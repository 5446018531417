// src/hooks/useMixpanel.js
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

let mixpanelInitialized = false;

export default function useMixpanel() {
  useEffect(() => {
    if (!mixpanelInitialized) {
      const token = process.env.REACT_APP_MIXPANEL_TOKEN;
      if (token) {
        mixpanel.init(token);
        mixpanelInitialized = true;
      }
      // Token not found case handled silently
    }
  }, []);
}