import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import axios from 'axios';
import { 
  UserCircleIcon, 
  ArrowLeftIcon, 
  CalendarIcon, 
  CreditCardIcon, 
  ClockIcon,
  ArrowRightOnRectangleIcon,
  TrashIcon
} from '@heroicons/react/24/outline';

const MyProfile = () => {
  const { user, logOut } = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Fetch user data when component mounts
  useEffect(() => {
    if (user) {
      setIsLoading(true);
      axios.get(`${API_BASE_URL}/api/landing-data/${user.user_id}`)
        .then((response) => {
          setUserData(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
          setError('사용자 정보를 불러오는데 실패했습니다.');
          setIsLoading(false);
        });
    }
  }, [user, API_BASE_URL]);

  // Function to handle logout
  const handleLogout = () => {
    logOut();
    navigate('/login');
  };

  // Function to handle account deletion
  const handleDeleteAccount = async () => {
    if (!user) return;
    
    setIsLoading(true);
    try {
      // Check if token exists in local storage
      const token = localStorage.getItem('token');
      if (!token) {
        setError('로그인이 필요합니다.');
        setIsLoading(false);
        return;
      }

      // Send API request to delete account
      await axios.delete(`${API_BASE_URL}/api/users/${user.user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      // Log out the user after account deletion
      logOut();
      navigate('/login');
    } catch (error) {
      console.error('Error deleting account:', error);
      setError('계정 삭제에 실패했습니다. 고객센터에 문의해주세요.');
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '없음';
    return new Date(dateString).toLocaleDateString('ko-KR', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8 flex items-center">
          <button 
            onClick={() => navigate('/')}
            className="mr-4 p-2 rounded-full hover:bg-gray-100"
          >
            <ArrowLeftIcon className="h-5 w-5 text-gray-600" />
          </button>
          <h1 className="text-xl font-semibold text-gray-900">내 프로필</h1>
        </div>
      </header>

      {/* Main content */}
      <main className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#3488fe]"></div>
          </div>
        ) : error ? (
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
            {error}
          </div>
        ) : (
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            {/* Profile header with user info */}
            <div className="px-4 py-5 sm:px-6 flex items-center border-b border-gray-200">
              <UserCircleIcon className="h-16 w-16 text-[#3488fe]" />
              <div className="ml-5">
                <h2 className="text-xl font-bold text-gray-900">{user?.name || '사용자'}</h2>
                <p className="mt-1 text-sm text-gray-500">{user?.user_id || '이메일 정보 없음'}</p>
              </div>
            </div>

            {/* Profile details */}
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <CalendarIcon className="h-5 w-5 mr-2 text-gray-400" />
                    시험 D-day
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {userData.days_until_exam ? `${userData.days_until_exam}일 남음` : '설정되지 않음'}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <CreditCardIcon className="h-5 w-5 mr-2 text-gray-400" />
                    모의고사 응시권
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {userData.mockTestCredits || 0}회 남음
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <ClockIcon className="h-5 w-5 mr-2 text-gray-400" />
                    구독 기간
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {userData.subscriptionEndDate ? 
                      `${formatDate(userData.subscriptionEndDate)}까지` : 
                      '구독 중이 아님'}
                  </dd>
                </div>
              </dl>
            </div>

            {/* Action buttons */}
            <div className="px-4 py-5 sm:px-6 space-y-4">
              <button
                onClick={handleLogout}
                className="w-full flex justify-center items-center px-4 py-3 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
              >
                <ArrowRightOnRectangleIcon className="h-5 w-5 mr-2 text-gray-700" />
                <span className="text-gray-700 font-medium">로그아웃</span>
              </button>
              
              <button
                onClick={() => setIsDeleteModalOpen(true)}
                className="w-full flex justify-center items-center px-4 py-3 bg-red-50 rounded-lg hover:bg-red-100 transition-colors"
              >
                <TrashIcon className="h-5 w-5 mr-2 text-red-600" />
                <span className="text-red-600 font-medium">계정 삭제</span>
              </button>
            </div>
          </div>
        )}
      </main>

      {/* Delete Account Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">계정 삭제 확인</h3>
            <p className="text-sm text-gray-500 mb-4">
              계정을 삭제하면 모든 사용자 데이터와 구매 내역이 영구적으로 삭제됩니다. 이 작업은 되돌릴 수 없습니다.
            </p>
            <div className="flex flex-col space-y-3 sm:flex-row-reverse sm:space-y-0 sm:space-x-3 sm:space-x-reverse">
              <button
                onClick={handleDeleteAccount}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                disabled={isLoading}
              >
                {isLoading ? '처리 중...' : '삭제'}
              </button>
              <button
                onClick={() => setIsDeleteModalOpen(false)}
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                취소
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyProfile; 