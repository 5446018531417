import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../context/AuthProvider';
import RecordButton from '../components/RecordButton';
import axios from 'axios';
import { SparklesIcon, HomeIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const PracticePart2 = () => {
  const { user } = useAuth();
  const [image, setImage] = useState('');
  const [annotation, setAnnotation] = useState('');
  const [questionId, setQuestionId] = useState('');
  const [progress, setProgress] = useState({ completed: 0, total: 50 });
  const [completedQuestions, setCompletedQuestions] = useState([]);
  const [timer, setTimer] = useState(45);  // Start with preparation timer set to 45 seconds
  const [recording, setRecording] = useState(false);
  const [recordingCompleted, setRecordingCompleted] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [aiFeedback, setAiFeedback] = useState('');
  const [activeTab, setActiveTab] = useState('transcription');
  const [hasSubscription, setHasSubscription] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [imageLoading, setImageLoading] = useState(true);
  const [imageError, setImageError] = useState(false);

  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const timerRef = useRef(null);
  const nextImageRef = useRef(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  // Fetch the user's progress and the first question
  useEffect(() => {
    if (user) {
      mixpanel.track('Part 2 Practice Started', { user_id: user.user_id });
      
      Promise.all([
        axios.get(`${API_BASE_URL}/api/get-part2-progress/${user.user_id}`),
        axios.get(`${API_BASE_URL}/api/landing-data/${user.user_id}`)
      ])
        .then(([progressResponse, landingResponse]) => {
          // Handle progress data
          const { completed, total, completedQuestions } = progressResponse.data;
          setProgress({ completed, total });
          setCompletedQuestions(completedQuestions);

          // Handle subscription data
          const { subscriptionEndDate } = landingResponse.data;
          
          let isActive = false;
          if (subscriptionEndDate) {
            const currentDate = new Date().toISOString();
            const endDate = new Date(subscriptionEndDate).toISOString();
            isActive = endDate > currentDate;
          }

          // Set subscription status and THEN fetch the next question
          setHasSubscription(isActive);
          
          // Calculate next question
          const nextQuestionId = completed === 0 ? 'p2_i_001' : `p2_i_${String(completed + 1).padStart(3, '0')}`;
          
          if (isActive || nextQuestionId === 'p2_i_001') {
            axios.get(`${API_BASE_URL}/api/get-part2-question-by-id/${nextQuestionId}`)
              .then((response) => {
                setImage(response.data.image);
                setAnnotation(response.data.annotation);
                setQuestionId(nextQuestionId);
                resetStateForNextQuestion();
                startCountdown(45);
              })
              .catch(error => {
                console.error("Error fetching question:", error);
              });
          } else {
            setIsModalOpen(true);
          }
        })
        .catch(error => {
          console.error("Error fetching initial data:", error);
        });
    }
  }, [user, API_BASE_URL]);

  useEffect(() => {
    // Automatically start recording when preparation timer hits 0 and it's not recording yet
    if (timer === 0 && !recording && !recordingCompleted) {
      startRecordingTimer();
    }
  
    // Automatically stop recording when the recording timer hits 0
    if (timer === 0 && recording) {
      stopRecording();
    }
  }, [timer, recording, recordingCompleted]);

  const fetchNextQuestion = (nextQuestionId) => {
    if (!user) return;

    // Check subscription status
    if (!hasSubscription && nextQuestionId !== 'p2_i_001') {
      setIsModalOpen(true);
      return;
    }

    setImageLoading(true);
    setImageError(false);

    axios.get(`${API_BASE_URL}/api/get-part2-question-by-id/${nextQuestionId}`)
      .then((response) => {
        setImage(response.data.image);
        setAnnotation(response.data.annotation);
        setQuestionId(nextQuestionId);
        resetStateForNextQuestion();
        startCountdown(45);

        // Preload next image
        const nextIdNumber = parseInt(nextQuestionId.split('_').pop()) + 1;
        const nextNextQuestionId = `p2_i_${String(nextIdNumber).padStart(3, '0')}`;
        preloadNextImage(nextNextQuestionId);
      })
      .catch(error => {
        console.error("Error fetching question:", error);
        setImageError(true);
        setImageLoading(false);
      });
  };
 

  const fetchPreviousQuestion = () => {
    if (questionId === 'p2_i_001') return;
    const prevIdNumber = parseInt(questionId.split('_').pop()) - 1;
    const prevQuestionId = `p2_i_${String(prevIdNumber).padStart(3, '0')}`;
    fetchNextQuestion(prevQuestionId);
  };

  const resetStateForNextQuestion = () => {
    clearInterval(timerRef.current);
    setTimer(45);
    setRecording(false);
    setRecordingCompleted(false);
    setTranscription('');
    setAiFeedback('');
    setActiveTab('transcription');
  };

  const startCountdown = (duration) => {
    // Clear any existing timer first
    clearInterval(timerRef.current);
    
    // Set initial timer value
    setTimer(duration);
    
    // Start new countdown
    timerRef.current = setInterval(() => {
      setTimer(prev => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const startRecordingTimer = () => {
    clearInterval(timerRef.current);
    setTimer(30);  // Set recording timer to 30 seconds
    setRecording(true);
    setRecordingCompleted(false);
    startCountdown(30);  // Start the 30-second recording timer
    handleStartRecording();

    mixpanel.track('Part 2 Recording Started', { user_id: user.user_id, question_id: questionId });
  };

  const handleStartRecording = async () => {
    try {
      const permissionResult = await navigator.permissions.query({ name: 'microphone' });
      
      if (permissionResult.state === 'denied') {
        showError("마이크 접근이 차단되어있습니다. 브라우저 설정에서 마이크 권한을 허용해주세요.");
        return;
      }
    } catch (err) {
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Log supported MIME types
      const supportedTypes = [
        'audio/webm',
        'audio/wav',
        'audio/mp4',
        'audio/ogg',
        'audio/mpeg'
      ].filter(type => MediaRecorder.isTypeSupported(type));
      
      // Use the first supported type, with fallback for Safari
      let mimeType;
      if (supportedTypes.includes('audio/wav')) {
        mimeType = 'audio/wav';
      } else if (supportedTypes.includes('audio/mp4')) {
        mimeType = 'audio/mp4';  // Safari often supports this
      } else {
        mimeType = supportedTypes[0];
      }

      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: mimeType,
        audioBitsPerSecond: 128000
      });
      recordedChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.start(1000); // Get data every second

    } catch (err) {
      console.error("Error in recording process:", err);
      
      if (err.name === 'NotAllowedError') {
        showError("마이크 접근이 거부되었습니다. 마이크 권한을 허용해주세요.");
      } else if (err.name === 'NotFoundError') {
        showError("마이크를 찾을 수 없습니다. 마이크가 연결되어 있는지 확인해주세요.");
      } else {
        showError("녹음을 시작할 수 없습니다. 마이크 연결을 확인해주세요.");
      }
      setRecording(false);
      clearInterval(timerRef.current);
    }
  };

  const stopRecording = () => {
    clearInterval(timerRef.current);
    setRecording(false);  // Mark recording as stopped
    setTimer(0);  // Reset timer to 0

    handleStopRecording();
    setRecordingCompleted(true);  // Set recording as completed

    mixpanel.track('Part 2 Recording Stopped', { user_id: user.user_id, question_id: questionId });
  };

  const handleStopRecording = () => {
    if (!mediaRecorderRef.current || mediaRecorderRef.current.state === 'inactive') {
      console.error("MediaRecorder not initialized or inactive");
      showError("녹음이 제대로 초기화되지 않았습니다. 다시 시도해주세요.");
      return;
    }

    mediaRecorderRef.current.requestData(); // Request any final chunks
    mediaRecorderRef.current.stop();

    const stream = mediaRecorderRef.current.stream;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => {
        track.stop();
      });
    }

    mediaRecorderRef.current.onstop = async () => {
      // Check for empty recording
      if (!recordedChunksRef.current.length) {
        console.error("No recorded chunks available!");
        showError("녹음된 음성이 없습니다. 마이크가 제대로 작동하는지 확인 후 다시 시도해주세요.");
        return;
      }

      // Check for very small recording
      const totalSize = recordedChunksRef.current.reduce((acc, chunk) => acc + chunk.size, 0);
      if (totalSize < 1000) {
        console.error("Recording too small:", totalSize, "bytes");
        showError("녹음된 음성이 너무 짧거나 비어있습니다. 다시 시도해주세요.");
        return;
      }

      const blob = new Blob(recordedChunksRef.current, { type: 'audio/webm' });
      const file = new File([blob], 'recording.webm', { type: 'audio/webm' });

      if (blob.size < 1000) {
        console.error("Blob too small:", blob.size, "bytes");
        showError("녹음된 음성이 너무 짧거나 비어있습니다. 다시 시도해주세요.");
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('model_answer', annotation);

      try {
        const response = await axios.post(`${API_BASE_URL}/api/speech-to-text`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        // Check if the transcription is in English
        if (!isEnglish(response.data.transcription)) {
          showError("답변은 영어로 해주세요. Please answer in English.");
          return;
        }

        // If we get here, the recording is valid and in English
        setTranscription(response.data.transcription);
        setAiFeedback(response.data.ai_feedback);
      } catch (error) {
        console.error("Error processing audio:", error);
        showError("음성 처리 중 오류가 발생했습니다. 다시 시도해주세요.");
      }
    };

    mediaRecorderRef.current = null;
  };

  // Helper function to check if text is primarily English
  const isEnglish = (text) => {
    // Remove punctuation and numbers
    const cleanText = text.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\d]/g, "");
    // Count words
    const words = cleanText.trim().split(/\s+/);
    // Count words that contain only English letters
    const englishWords = words.filter(word => /^[a-zA-Z]+$/.test(word));
    
    // Consider it English if at least 70% of words are English
    return englishWords.length / words.length >= 0.7;
  };

  // Helper function to reset recording state
  const resetRecordingState = () => {
    setRecording(false);
    setRecordingCompleted(false);
    recordedChunksRef.current = [];
    setTranscription('');
    setAiFeedback('');
    setTimer(45); // Reset to preparation time
  };

  const handleNextQuestion = () => {
    if (!user || !questionId) return;

    // Get fresh subscription status from server
    axios.get(`${API_BASE_URL}/api/landing-data/${user.user_id}`)
      .then((response) => {
        const { subscriptionEndDate } = response.data;
        const currentDate = new Date().toISOString();
        const endDate = new Date(subscriptionEndDate).toISOString();
        const isActive = endDate > currentDate;

        if (isActive) {
          axios.post(`${API_BASE_URL}/api/complete-part2-question/${user.user_id}/${questionId}`)
            .then(() => {
              setProgress(prev => ({
                ...prev,
                completed: prev.completed + 1,
              }));
              const nextIdNumber = parseInt(questionId.split('_').pop()) + 1;
              const nextQuestionId = `p2_i_${String(nextIdNumber).padStart(3, '0')}`;
              
              // Fetch next question directly instead of using fetchNextQuestion
              axios.get(`${API_BASE_URL}/api/get-part2-question-by-id/${nextQuestionId}`)
                .then((response) => {
                  setImage(response.data.image);
                  setAnnotation(response.data.annotation);
                  setQuestionId(nextQuestionId);
                  resetStateForNextQuestion();
                  startCountdown(45);
                })
                .catch(error => {
                  console.error("Error fetching next question:", error);
                });

              mixpanel.track('Part 2 Question Completed', { user_id: user.user_id, question_id: questionId });
            })
            .catch(error => {
              console.error("Error completing question:", error);
            });
        } else {
          setIsModalOpen(true);
        }
      })
      .catch(error => {
        console.error("Error checking subscription:", error);
      });
  };

  // Split AI feedback based on numbers (like 1., 2.) or dashes (-) and handle **bold** formatting
  const formatAiFeedback = (feedback) => {
    // Split by numbered points, bullet point dashes (followed by space), and bold text
    const parts = feedback.split(/(\d+\.\s|-\s|\*\*.*?\*\*)/);
  
    return parts.map((part, index, array) => {
      // Bold if part is wrapped in ** **
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      }
      
      // Add a new line before numbers with periods or dashes with spaces ONLY if it's not at the start
      if (part.match(/\d+\.\s|-\s/)) {  // Note: -\s means dash followed by whitespace
        // Check if this is effectively the start of the content
        const previousContent = array.slice(0, index).join('').trim();
        if (previousContent) {
          return <><br key={index} />{part}</>;
        }
        return part;
      }
      return part;
    });
  };
  const fetchQuestionById = (id) => {
    const questionId = `p2_i_${String(id).padStart(3, '0')}`;
    fetchNextQuestion(questionId);
  };
  const progressPercentage = (completedQuestions.length / progress.total) * 100;

  const handleDropdownChange = (event) => {
    const selectedQuestionId = parseInt(event.target.value);
    if (selectedQuestionId <= progress.completed) {
      fetchQuestionById(selectedQuestionId);
    }
  };
  const handleHomeClick = () => {
    navigate('/'); // Redirect to the landing page
  };

  const handleTabSwitch = (tab) => {
    setActiveTab(tab);

    // Track tab click event
    mixpanel.track('Part 2 Feedback Tab Clicked', { user_id: user.user_id, tab });
  };

  // Update the ErrorModal component with a friendlier design
  const ErrorModal = ({ message, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4 relative">
        <div className="text-center">
          {/* Friendly Icon */}
          <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-blue-50 mb-4">
            <svg className="h-10 w-10 text-[#3488fe]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          
          <h3 className="text-xl font-medium text-gray-900 mb-4">
            알림
          </h3>
          
          <p className="text-base text-gray-600 mb-6">
            {message}
          </p>
          
          <div className="mt-5">
            <button
              onClick={onClose}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-[#3488fe] text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm transition-colors duration-200"
            >
              다시 시도하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  // Update handleErrorModalClose to properly reset everything and restart the timer
  const handleErrorModalClose = () => {
    setShowErrorModal(false);
    
    // Clean up recording state
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }
    }
    mediaRecorderRef.current = null;
    
    // Reset all states
    setRecording(false);
    setRecordingCompleted(false);
    recordedChunksRef.current = [];
    setTranscription('');
    setAiFeedback('');
    
    // Clear any existing timer
    clearInterval(timerRef.current);
    
    // Reset and start the preparation timer
    setTimer(45);
    startCountdown(45);
  };

  // Update showError to include the timer state
  const showError = (message) => {
    // Stop any ongoing recording
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }
    }
    
    // Clear the current timer
    clearInterval(timerRef.current);
    
    // Show error modal
    setErrorMessage(message);
    setShowErrorModal(true);
  };

  // Add image preloading function
  const preloadNextImage = (nextQuestionId) => {
    if (!nextQuestionId) return;
    
    axios.get(`${API_BASE_URL}/api/get-part2-question-by-id/${nextQuestionId}`)
      .then((response) => {
        const nextImage = new Image();
        nextImage.src = `${process.env.REACT_APP_S3_BASE_URL}/images/${response.data.image}`;
        nextImageRef.current = nextImage;
      })
      .catch(error => {
        console.error("Error preloading next image:", error);
      });
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-start p-4">
      <div className="w-full max-w-4xl">
        {/* Top Row: Previous Button and Title */}
        <div className="flex justify-between items-center mb-4">
          {/* {questionId !== 'p2_i_001' && ( */}
            <button className="text-base sm:text-lg" onClick={handleHomeClick}>
              <HomeIcon className="w-6 h-6" /> {/* Replace text with HomeIcon */}
            </button>
          {/* )} */}
          <h1 className="text-base sm:text-lg font-bold">Part 2 연습</h1>
          <div className="w-24"></div> {/* Placeholder for alignment */}
        </div>

        {/* Dropdown for Questions */}
        <div className="mb-4">
          <select
            className="border rounded p-2 w-full"
            value={parseInt(questionId.split('_')[2])}  // Correctly extract the current question number
            onChange={handleDropdownChange}
          >
            {Array.from({ length: progress.completed + 1 }, (_, i) => i + 1).map(number => (
              <option key={number} value={number}>
                Question {number}
              </option>
            ))}
          </select>
        </div>

        {/* Progress Bar */}
        <div className="mb-4">
          <p className="text-base sm:text-lg">진행상황</p>
          <div className="relative w-full h-4 bg-gray-300 rounded">
            <div
              className="absolute h-full bg-[#3488fe] rounded"
              style={{ width: `${progressPercentage}%` }}
            ></div>

            {/* Display the completed number under the progress point */}
            <div
              className="absolute top-6 text-sm sm:text-base"
              style={{ left: `calc(${progressPercentage}% - 10px)` }} // Adjust the position
            >
              {completedQuestions.length}
            </div>
          </div>

          {/* Total number on the right */}
          <div className="flex justify-between mt-2 text-sm sm:text-base">
            <span></span> {/* Empty span for alignment */}
            <span>{progress.total}</span>
          </div>
        </div>

        <div className="w-full flex justify-center mb-4">
          <div className="w-96 h-96 flex items-center justify-center bg-gray-100 rounded-lg relative">
            {imageLoading && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="animate-pulse flex space-x-4">
                  <div className="w-96 h-96 bg-gray-200 rounded-lg"></div>
                </div>
              </div>
            )}
            
            {imageError ? (
              <div className="flex flex-col items-center justify-center text-gray-500">
                <svg className="w-12 h-12 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
                <p>이미지를 불러올 수 없습니다</p>
                <button 
                  onClick={() => {
                    setImageError(false);
                    setImageLoading(true);
                    // Retry loading the current image
                    const img = new Image();
                    img.src = `${process.env.REACT_APP_S3_BASE_URL}/images/${image}?${new Date().getTime()}`;
                  }}
                  className="mt-4 text-blue-500 hover:text-blue-700"
                >
                  다시 시도
                </button>
              </div>
            ) : (
              image && (
                <img 
                  src={`${process.env.REACT_APP_S3_BASE_URL}/images/${image}`}
                  alt="Baetos Part2 Question Assets"
                  className={`max-w-full max-h-full object-contain rounded-lg transition-opacity duration-300 ${
                    imageLoading ? 'opacity-0' : 'opacity-100'
                  }`}
                  onLoad={() => {
                    setImageLoading(false);
                    // Preload next image
                    const nextIdNumber = parseInt(questionId.split('_').pop()) + 1;
                    const nextQuestionId = `p2_i_${String(nextIdNumber).padStart(3, '0')}`;
                    preloadNextImage(nextQuestionId);
                  }}
                  onError={(e) => {
                    console.error('Image failed to load:', {
                      src: e.target.src,
                      error: e
                    });
                    setImageError(true);
                    setImageLoading(false);
                  }}
                />
              )
            )}
          </div>
        </div>

        <div className="mt-4 flex flex-col items-center">
          <p className="text-lg mb-8">Time left: {timer}초</p>
          <RecordButton
            recording={recording}
            onStart={startRecordingTimer}
            onStop={stopRecording}
            buttonText={
              recording ? "Stop Recording" : (recordingCompleted ? "Re-try" : "Start Recording")
            }
          />
        </div>

        {transcription && (
          <div className="mt-6">
            {/* Tabs */}
            <div className="flex justify-between border-b-2 border-[#E5E8EB]">
              <div
                className={`cursor-pointer text-lg ${
                  activeTab === 'transcription' 
                    ? 'border-b-4 border-[#3488fe] text-black' 
                    : 'border-none'
                }`}
                onClick={() => handleTabSwitch('transcription')}
              >
                나의 답변
              </div>
              <div
                className={`cursor-pointer text-lg flex items-center ${
                  activeTab === 'aiFeedback' 
                    ? 'border-b-4 border-[#3488fe] text-black' 
                    : 'border-none'
                }`}
                onClick={() => handleTabSwitch('aiFeedback')}
              >
                AI 피드백
                <SparklesIcon className="w-5 h-5 ml-2 text-yellow-500" />
              </div>
              <div
                className={`cursor-pointer text-lg ${
                  activeTab === 'modelAnswer' 
                    ? 'border-b-4 border-[#3488fe] text-black' 
                    : 'border-none'
                }`}
                onClick={() => handleTabSwitch('modelAnswer')}
              >
                모범답안
              </div>
            </div>

            {/* Tab Contents */}
            {activeTab === 'transcription' && (
              <div className="mt-4 p-4 bg-white shadow-lg rounded-lg">
                {/* <h2 className="text-xl font-bold">Your Answer</h2> */}
                <p>{transcription}</p>
              </div>
            )}

            {activeTab === 'aiFeedback' && (
              <div className="mt-4 p-4 bg-white shadow-lg rounded-lg">
                {/* <h2 className="text-xl font-bold">AI Feedback</h2> */}
                <p>{formatAiFeedback(aiFeedback)}</p>
              </div>
            )}

            {activeTab === 'modelAnswer' && (
              <div className="mt-4 p-4 bg-white shadow-lg rounded-lg">
                {/* <h2 className="text-xl font-bold">Model Answer</h2> */}
                <p>{annotation}</p>
              </div>
            )}

            {/* Next Question Button */}
            <button
              onClick={handleNextQuestion}
              className="px-6 py-3 mt-6 w-full rounded-lg bg-[#3488fe] text-white text-base sm:text-lg"
            >
              Next Question
            </button>
          </div>
        )}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-md">
            <h2 className="text-xl font-bold mb-4">구독이 필요합니다</h2>
            <p className="mb-4">더 많은 문제를 풀어보시려면 구독해주세요.</p>
            <button
              onClick={() => navigate('/pricing')}
              className="w-full bg-[#3488fe] text-white py-2 rounded-lg"
            >
              구독하기
            </button>
          </div>
        </div>
      )}

      {/* Add Error Modal */}
      {showErrorModal && (
        <ErrorModal
          message={errorMessage}
          onClose={handleErrorModalClose}
        />
      )}
    </div>
  );
};

export default PracticePart2;