// src/pages/PricingPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import { ArrowLeftIcon, ClockIcon, DocumentCheckIcon, SparklesIcon } from '@heroicons/react/24/outline';
import axios from 'axios';

const PricingPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState('combo');
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const [mockTestCredits, setMockTestCredits] = useState(0);

  useEffect(() => {
    // Initialize PortOne SDK
    const script = document.createElement('script');
    script.src = "https://cdn.iamport.kr/v1/iamport.js";
    script.async = true;
    document.body.appendChild(script);

    // Fetch user subscription data
    if (user) {
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/landing-data/${user.user_id}`)
        .then((response) => {
          const { mockTestCredits, subscriptionEndDate } = response.data;
          setMockTestCredits(mockTestCredits);
          setSubscriptionEndDate(subscriptionEndDate);
        })
        .catch((error) => {
          console.error("Error fetching subscription data:", error);
        });
    }

    return () => {
      document.body.removeChild(script);
    };
  }, [user]);

  const handlePurchase = async (plan) => {
    if (!user) {
      navigate('/login');
      return;
    }

    // Validate required user information
    if (!user.user_id) {
      alert('사용자 정보가 올바르지 않습니다. 다시 로그인해주세요.');
      navigate('/login');
      return;
    }

    let paymentData = {
      pg: `kakaopay.${process.env.REACT_APP_KAKAOPAY_CID}`,
      pay_method: 'card',
      merchant_uid: `ORD${Date.now()}`,
      name: '',
      amount: 0,
      buyer_email: user.user_id,
      buyer_name: user.name || '구매자',
      buyer_tel: '010-0000-0000',
      m_redirect_url: `${window.location.origin}/payments/complete`,
      popup: false
    };

    // Set payment details based on plan
    try {
      switch (plan) {
        case 'single-mock':
          paymentData.name = '1회 모의고사';
          paymentData.amount = 12900;
          break;
        case 'three-mock':
          paymentData.name = '3회 모의고사';
          paymentData.amount = 23900;
          break;
        case 'five-mock':
          paymentData.name = '5회 모의고사';
          paymentData.amount = 29900;
          break;
        case '7-days':
          paymentData.name = '7일 이용권';
          paymentData.amount = 12900;
          break;
        case '14-days':
          paymentData.name = '14일 이용권';
          paymentData.amount = 19900;
          break;
        case '1-month':
          paymentData.name = '1개월 이용권';
          paymentData.amount = 22900;
          break;
        case 'sparta-2weeks':
          paymentData.name = '2주 스파르타 패키지';
          paymentData.amount = 29900;
          break;
        case 'sparta-month':
          paymentData.name = '1달 스파르타 패키지';
          paymentData.amount = 39900;
          break;
        default:
          throw new Error('Invalid plan selected');
      }

      if (!window.IMP) {
        throw new Error('결제 모듈을 불러오는데 실패했습니다.');
      }

      // Initialize PortOne
      const IMP = window.IMP;
      IMP.init(process.env.REACT_APP_PORTONE_IMP_ID || 'imp24182010');

      // Request Payment
      IMP.request_pay(paymentData, async function(rsp) {
        console.log("Payment response:", rsp);

        if (!rsp) {
          console.error('Payment response is null or undefined');
          alert('결제 처리 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
          return;
        }

        if (rsp.success || rsp.status === 'paid') {
          try {
            // Get the current token
            const token = localStorage.getItem('token');
            if (!token) {
              alert('로그인이 필요합니다. 다시 로그인해주세요.');
              navigate('/login');
              return;
            }

            const verifyResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/payments/verify`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                imp_uid: rsp.imp_uid,
                merchant_uid: rsp.merchant_uid,
                amount: paymentData.amount,
                product_name: paymentData.name
              }),
            });

            if (!verifyResponse.ok) {
              const errorData = await verifyResponse.json();
              console.error('Payment verification failed:', errorData);
              
              // Handle token expiration
              if (verifyResponse.status === 401 && errorData.detail === 'Token has expired') {
                alert('세션이 만료되었습니다. 다시 로그인해주세요.');
                localStorage.removeItem('token'); // Clear the expired token
                navigate('/login');
                return;
              }
              
              throw new Error(errorData.detail || '결제 확인 중 오류가 발생했습니다.');
            }

            const result = await verifyResponse.json();
            console.log('Payment verification result:', result);
            
            if (result.success) {
              alert('결제가 완료되었습니다!');
              // Refresh user data
              window.location.reload();
            } else {
              throw new Error(result.message || '결제 확인에 실패했습니다.');
            }
          } catch (error) {
            console.error('Payment verification error:', error);
            alert(`결제 확인 중 오류가 발생했습니다.\n${error.message}\n\n고객센터로 문의해주세요.`);
          }
        } else {
          console.error('Payment failed:', rsp);
          const errorMessage = rsp.error_msg || '알 수 없는 오류';
          alert(`결제에 실패하였습니다.\n사유: ${errorMessage}`);
        }
      });
    } catch (error) {
      console.error('Payment initiation error:', error);
      alert(`결제 시작 중 오류가 발생했습니다: ${error.message}`);
    }
  };

  const PricingCard = ({ 
    title, 
    price, 
    originalPrice, 
    period, 
    discount, 
    isPopular, 
    isBestValue,
    onClick,
    highlighted = false 
  }) => (
    <div className={`bg-gray-50 p-6 rounded-lg flex flex-col h-full ${
      highlighted ? 'border-2 border-[#3488fe]' : 'border border-gray-200'
    }`}>
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-lg md:text-xl font-semibold">{title}</h3>
        {(isPopular || isBestValue) && (
          <span className="bg-[#3488fe] text-white px-2 py-1 rounded-lg text-xs md:text-sm">
            {isPopular ? 'Most Popular' : 'Best Value'}
          </span>
        )}
      </div>
      
      <div className="flex-grow">
        <div className="mb-6">
          <p className="text-2xl md:text-3xl font-bold text-[#3488fe]">
            ₩{price.toLocaleString()}
            {period && <span className="text-sm md:text-base font-normal">/{period}</span>}
          </p>
          {originalPrice && (
            <div className="mt-1">
              <p className="text-base md:text-lg text-gray-600">
                <span className="line-through text-gray-400">₩{originalPrice.toLocaleString()}</span>
                {" → "}
                <span>₩{price.toLocaleString()}</span>
              </p>
            </div>
          )}
          {discount && (
            <div className="mt-2">
              <span className="bg-red-100 text-red-600 px-2 py-1 rounded-full text-xs md:text-sm">
                {discount}% 할인
              </span>
            </div>
          )}
        </div>
      </div>

      <button
        onClick={onClick}
        className="w-full bg-[#3488fe] text-white py-3 rounded-lg hover:bg-[#2670e8] transition-colors font-medium"
      >
        구매하기
      </button>
    </div>
  );

  const TabButton = ({ id, label, active, onClick }) => (
    <button
      onClick={() => onClick(id)}
      className={`px-6 py-3 rounded-lg text-sm md:text-base font-medium transition-all duration-300 relative
        ${active ? 'bg-[#3488fe] text-white' : 'bg-gray-100 text-gray-600 hover:bg-gray-200'}
        before:absolute before:inset-0 before:rounded-lg before:border-2 before:border-transparent
        ${active ? 'before:border-[#3488fe] before:animate-pulse' : 'hover:before:border-gray-300'}
        `}
    >
      {label}
    </button>
  );

  const FeatureList = ({ features }) => (
    <div className="space-y-3">
      {features.map((item, index) => (
        <div key={index} className="flex items-center gap-2">
          <span className="text-[#3488fe] text-lg">•</span>
          <span className="text-gray-700">{item}</span>
        </div>
      ))}
    </div>
  );

  const renderTabContent = () => {
    switch (activeTab) {
      case 'mock':
        return (
          <div className="flex flex-col gap-8">
            <div className="flex flex-col md:flex-row gap-8">
              <div className="md:w-1/3">
                <h3 className="text-lg font-bold text-gray-700 mb-6">포함사항</h3>
                <div className="space-y-4">
                  {[
                    '2025년 최신 경향 반영',
                    '실전과 동일한 환경',
                    'ETS 토익스피킹 채점 기준 기반 점수 측정',
                    'ETS 토익스피킹 결과지와 동일 형식 리포트 제공',
                    '채점에 소요되는 시간은 3분 이내',
                  ].map((feature, index) => (
                    <div key={index} className="flex items-start gap-4">
                      <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-50 flex items-center justify-center mt-0.5">
                        <span className="text-[#3488fe] text-sm">✓</span>
                      </div>
                      <span className="text-gray-700">{feature}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="md:w-2/3">
                <div className="space-y-6 max-w-3xl mx-auto">
                  {/* Single Mock Test */}
                  <div className="bg-white p-8 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-all">
                    <div className="flex flex-col md:flex-row justify-between items-center gap-6">
                      <div className="md:w-1/3">
                        <h3 className="text-xl font-bold text-gray-800 mb-2">1회 모의고사</h3>
                        <p className="text-gray-600">한 번의 모의고사로 실력 점검하기</p>
                      </div>
                      <div className="md:w-1/3 text-center">
                        <p className="text-3xl font-bold text-[#3488fe]">₩12,900</p>
                      </div>
                      <div className="md:w-1/3">
                        <button
                          onClick={() => handlePurchase('single-mock')}
                          className="w-full bg-gradient-to-r from-[#3488fe] to-blue-600 text-white py-4 rounded-xl hover:opacity-90 transition-opacity font-medium text-lg shadow-lg shadow-blue-200"
                        >
                          구매하기
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Three Mock Tests */}
                  <div className="bg-white p-8 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-all">
                    <div className="flex flex-col md:flex-row justify-between items-center gap-6">
                      <div className="md:w-1/3">
                        <h3 className="text-xl font-bold text-gray-800 mb-2">3회 모의고사</h3>
                        <p className="text-gray-600">실력 향상도 측정하기</p>
                      </div>
                      <div className="md:w-1/3 text-center">
                        <p className="text-3xl font-bold text-[#3488fe]">₩23,900</p>
                        <div className="mt-2">
                          <p className="text-gray-600">
                            <span className="line-through text-gray-400">₩38,700</span>
                            <span className="ml-2 inline-block bg-red-50 text-red-600 px-2 py-1 rounded-full text-sm">38% 할인</span>
                          </p>
                        </div>
                      </div>
                      <div className="md:w-1/3">
                        <button
                          onClick={() => handlePurchase('three-mock')}
                          className="w-full bg-gradient-to-r from-[#3488fe] to-blue-600 text-white py-4 rounded-xl hover:opacity-90 transition-opacity font-medium text-lg shadow-lg shadow-blue-200"
                        >
                          구매하기
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Five Mock Tests */}
                  <div className="bg-gradient-to-br from-[#3488fe]/[0.02] to-blue-100/20 p-8 rounded-xl border-2 border-[#3488fe] shadow-lg hover:shadow-xl transition-all relative overflow-hidden">
                    <div className="flex flex-col md:flex-row justify-between items-center gap-6">
                      <div className="md:w-1/3">
                        <h3 className="text-xl font-bold text-gray-800 mb-2">5회 모의고사</h3>
                        <p className="text-gray-600">완벽한 실전 대비 패키지</p>
                      </div>
                      <div className="md:w-1/3 text-center">
                        <p className="text-3xl font-bold text-[#3488fe]">₩29,900</p>
                        <div className="mt-2">
                          <p className="text-gray-600">
                            <span className="line-through text-gray-400">₩64,500</span>
                            <span className="ml-2 inline-block bg-red-50 text-red-600 px-2 py-1 rounded-full text-sm">58% 할인</span>
                          </p>
                        </div>
                      </div>
                      <div className="md:w-1/3">
                        <button
                          onClick={() => handlePurchase('five-mock')}
                          className="w-full bg-gradient-to-r from-[#3488fe] to-blue-600 text-white py-4 rounded-xl hover:opacity-90 transition-opacity font-medium text-lg shadow-lg shadow-blue-200"
                        >
                          구매하기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      
      case 'practice':
        return (
          <div className="flex flex-col gap-8">
            <div className="flex flex-col md:flex-row gap-8">
              <div className="md:w-1/3">
                <h3 className="text-lg font-bold text-gray-700 mb-6">포함사항</h3>
                <div className="space-y-4">
                  {[
                    '파트별 문제은행 무제한 이용/복습 가능',
                    '파트별 템플릿 만능 문장 영작 연습',
                    '문제은행/영작 연습 실시간 피드백 제공',
                    '독학으로 토스 정복하자!'
                  ].map((feature, index) => (
                    <div key={index} className="flex items-start gap-4">
                      <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-50 flex items-center justify-center mt-0.5">
                        <span className="text-[#3488fe] text-sm">✓</span>
                      </div>
                      <span className="text-gray-700">{feature}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="md:w-2/3">
                <div className="space-y-6 max-w-3xl mx-auto">
                  {/* 7 Days */}
                  <div className="bg-white p-8 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-all">
                    <div className="flex flex-col md:flex-row justify-between items-center gap-6">
                      <div className="md:w-1/3">
                        <h3 className="text-xl font-bold text-gray-800 mb-2">7일 이용권</h3>
                        <p className="text-gray-600">7일간 무제한 연습</p>
                      </div>
                      <div className="md:w-1/3 text-center">
                        <p className="text-3xl font-bold text-[#3488fe]">₩12,900</p>
                      </div>
                      <div className="md:w-1/3">
                        <button
                          onClick={() => handlePurchase('7-days')}
                          className="w-full bg-gradient-to-r from-[#3488fe] to-blue-600 text-white py-4 rounded-xl hover:opacity-90 transition-opacity font-medium text-lg shadow-lg shadow-blue-200"
                        >
                          구매하기
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* 14 Days */}
                  <div className="bg-white p-8 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-all">
                    <div className="flex flex-col md:flex-row justify-between items-center gap-6">
                      <div className="md:w-1/3">
                        <h3 className="text-xl font-bold text-gray-800 mb-2">14일 이용권</h3>
                        <p className="text-gray-600">2주 완성 집중 학습</p>
                      </div>
                      <div className="md:w-1/3 text-center">
                        <p className="text-3xl font-bold text-[#3488fe]">₩19,900</p>
                        <div className="mt-2">
                          <p className="text-gray-600">
                            <span className="line-through text-gray-400">₩25,800</span>
                            <span className="ml-2 inline-block bg-red-50 text-red-600 px-2 py-1 rounded-full text-sm">23% 할인</span>
                          </p>
                        </div>
                      </div>
                      <div className="md:w-1/3">
                        <button
                          onClick={() => handlePurchase('14-days')}
                          className="w-full bg-gradient-to-r from-[#3488fe] to-blue-600 text-white py-4 rounded-xl hover:opacity-90 transition-opacity font-medium text-lg shadow-lg shadow-blue-200"
                        >
                          구매하기
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* 1 Month */}
                  <div className="bg-gradient-to-br from-[#3488fe]/[0.02] to-blue-100/20 p-8 rounded-xl border-2 border-[#3488fe] shadow-lg hover:shadow-xl transition-all relative overflow-hidden">
                    <div className="flex flex-col md:flex-row justify-between items-center gap-6">
                      <div className="md:w-1/3">
                        <h3 className="text-xl font-bold text-gray-800 mb-2">1개월 이용권</h3>
                        <p className="text-gray-600">토스 완벽 대비 추천</p>
                      </div>
                      <div className="md:w-1/3 text-center">
                        <p className="text-3xl font-bold text-[#3488fe]">₩22,900</p>
                        <div className="mt-2">
                          <p className="text-gray-600">
                            <span className="line-through text-gray-400">₩51,600</span>
                            <span className="ml-2 inline-block bg-red-50 text-red-600 px-2 py-1 rounded-full text-sm">56% 할인</span>
                          </p>
                        </div>
                      </div>
                      <div className="md:w-1/3">
                        <button
                          onClick={() => handlePurchase('1-month')}
                          className="w-full bg-gradient-to-r from-[#3488fe] to-blue-600 text-white py-4 rounded-xl hover:opacity-90 transition-opacity font-medium text-lg shadow-lg shadow-blue-200"
                        >
                          구매하기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      
      case 'combo':
        return (
          <div className="grid md:grid-cols-2 gap-8">
            {/* 2주 스파르타 패키지 */}
            <div className="relative bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden border border-gray-100 flex flex-col">
              {/* Decorative elements */}
              <div className="absolute top-0 right-0 w-32 h-32 bg-blue-50 rounded-bl-full -z-0 opacity-50"></div>
              <div className="absolute bottom-0 left-0 w-24 h-24 bg-blue-50 rounded-tr-full -z-0 opacity-50"></div>
              
              <div className="relative z-10 p-8 flex-grow">
                {/* Header */}
                <div className="flex items-start justify-between mb-6">
                  <div>
                    <h3 className="text-2xl font-bold bg-gradient-to-r from-[#3488fe] to-blue-600 bg-clip-text text-transparent">2주 스파르타 패키지</h3>
                    <p className="text-gray-600 mt-2">단기간 집중 학습을 위한 선택</p>
                  </div>
                </div>

                {/* Price */}
                <div className="mb-8">
                  <div className="flex items-baseline gap-2">
                    <span className="text-3xl font-bold text-[#3488fe]">₩29,900</span>
                    <span className="text-gray-500 line-through">₩64,500</span>
                  </div>
                  <span className="inline-block mt-2 bg-red-50 text-red-600 px-2 py-1 rounded-full text-sm">58% 할인</span>
                </div>

                {/* Features */}
                <div className="py-6 border-y border-gray-100">
                  <h4 className="text-gray-500 text-sm mb-6">포함사항</h4>
                  <div className="space-y-6">
                    <div className="flex items-start gap-4">
                      <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-50 flex items-center justify-center mt-0.5">
                        <span className="text-[#3488fe] text-sm">✓</span>
                      </div>
                      <div>
                        <h4 className="text-gray-800 font-medium">모의고사 3회 응시권</h4>
                        <p className="text-gray-500 text-sm mt-0.5">실전과 동일한 환경에서 모의고사 응시</p>
                      </div>
                    </div>
                    <div className="flex items-start gap-4">
                      <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-50 flex items-center justify-center mt-0.5">
                        <span className="text-[#3488fe] text-sm">✓</span>
                      </div>
                      <div>
                        <h4 className="text-gray-800 font-medium">2주 무제한 파트별 문제은행</h4>
                        <p className="text-gray-500 text-sm mt-0.5">Part 1-5 전체 문제은행 무제한 이용</p>
                      </div>
                    </div>
                    <div className="flex items-start gap-4">
                      <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-50 flex items-center justify-center mt-0.5">
                        <span className="text-[#3488fe] text-sm">✓</span>
                      </div>
                      <div>
                        <h4 className="text-gray-800 font-medium">2주 무제한 템플릿 영작 연습</h4>
                        <p className="text-gray-500 text-sm mt-0.5">파트별 템플릿 만능 문장 영작 연습</p>
                      </div>
                    </div>
                    <div className="flex items-start gap-4">
                      <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-50 flex items-center justify-center mt-0.5">
                        <span className="text-[#3488fe] text-sm">✓</span>
                      </div>
                      <div>
                        <h4 className="text-gray-800 font-medium">실시간 AI 피드백</h4>
                        <p className="text-gray-500 text-sm mt-0.5">모든 파트 연습에 대한 실시간 분석 제공</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* CTA Button */}
              <div className="p-8 pt-0">
                <button
                  onClick={() => handlePurchase('sparta-2weeks')}
                  className="w-full bg-gradient-to-r from-[#3488fe] to-blue-600 text-white py-4 rounded-xl hover:opacity-90 transition-opacity font-medium text-lg shadow-lg shadow-blue-200"
                >
                  2주 패키지 시작하기
                </button>
              </div>
            </div>

            {/* 1달 스파르타 패키지 */}
            <div className="relative bg-gradient-to-br from-[#3488fe]/[0.02] to-blue-100/20 rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden border-2 border-[#3488fe] flex flex-col">
              {/* Decorative elements */}
              <div className="absolute top-0 right-0 w-40 h-40 bg-blue-100 rounded-bl-full -z-0 opacity-50"></div>
              <div className="absolute bottom-0 left-0 w-32 h-32 bg-blue-100 rounded-tr-full -z-0 opacity-50"></div>
              
              <div className="absolute top-6 right-6">
                <span className="bg-[#3488fe] text-white px-4 py-2 rounded-full text-sm font-medium shadow-lg">Best Value</span>
              </div>

              <div className="relative z-10 p-8 flex-grow">
                {/* Header */}
                <div className="mb-6">
                  <h3 className="text-2xl font-bold bg-gradient-to-r from-[#3488fe] to-blue-600 bg-clip-text text-transparent">1달 스파르타 패키지</h3>
                  <p className="text-gray-600 mt-2">완벽한 토스 독학을 위한 프리미엄 패키지</p>
                </div>

                {/* Price */}
                <div className="mb-8">
                  <div className="flex items-baseline gap-2">
                    <span className="text-3xl font-bold text-[#3488fe]">₩39,900</span>
                    <span className="text-gray-500 line-through">₩116,100</span>
                  </div>
                  <span className="inline-block mt-2 bg-red-50 text-red-600 px-2 py-1 rounded-full text-sm">66% 할인</span>
                </div>

                {/* Features for 1달 package */}
                <div className="py-6 border-y border-blue-100">
                  <h4 className="text-gray-500 text-sm mb-6">포함사항</h4>
                  <div className="space-y-6">
                    <div className="flex items-start gap-4">
                      <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 flex items-center justify-center mt-0.5">
                        <span className="text-[#3488fe] text-sm">✓</span>
                      </div>
                      <div>
                        <h4 className="text-gray-800 font-medium">모의고사 5회 응시권</h4>
                        <p className="text-gray-500 text-sm mt-0.5">실전과 동일한 환경에서 모의고사 응시</p>
                      </div>
                    </div>
                    <div className="flex items-start gap-4">
                      <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 flex items-center justify-center mt-0.5">
                        <span className="text-[#3488fe] text-sm">✓</span>
                      </div>
                      <div>
                        <h4 className="text-gray-800 font-medium">1개월 무제한 파트별 문제은행</h4>
                        <p className="text-gray-500 text-sm mt-0.5">Part 1-5 전체 문제은행 무제한 이용</p>
                      </div>
                    </div>
                    <div className="flex items-start gap-4">
                      <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 flex items-center justify-center mt-0.5">
                        <span className="text-[#3488fe] text-sm">✓</span>
                      </div>
                      <div>
                        <h4 className="text-gray-800 font-medium">1개월 무제한 템플릿 영작 연습</h4>
                        <p className="text-gray-500 text-sm mt-0.5">파트별 템플릿 만능 문장 영작 연습</p>
                      </div>
                    </div>
                    <div className="flex items-start gap-4">
                      <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 flex items-center justify-center mt-0.5">
                        <span className="text-[#3488fe] text-sm">✓</span>
                      </div>
                      <div>
                        <h4 className="text-gray-800 font-medium">실시간 AI 피드백</h4>
                        <p className="text-gray-500 text-sm mt-0.5">모든 파트 연습에 대한 실시간 분석 제공</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* CTA Button */}
              <div className="p-8 pt-0">
                <button
                  onClick={() => handlePurchase('sparta-month')}
                  className="w-full bg-gradient-to-r from-[#3488fe] to-blue-600 text-white py-4 rounded-xl hover:opacity-90 transition-opacity font-medium text-lg shadow-lg shadow-blue-200"
                >
                  1달 패키지 시작하기
                </button>
              </div>
            </div>
          </div>
        );
      
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen">
      {/* Back Button */}
      <button
        className="absolute top-4 left-4 text-xl z-10"
        onClick={() => navigate('/')}
      >
        <ArrowLeftIcon className="h-6 w-6 text-black" aria-hidden="true" />
      </button>

      {/* Hero Section */}
      <div className="relative bg-gradient-to-b from-blue-100 via-blue-50 to-blue-50 pt-16 pb-24">
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center mb-12">
            <h1 className="text-2xl md:text-4xl font-bold mb-6 text-gray-800">이용권 구매</h1>
            <div className="inline-flex items-center justify-center max-w-3xl mx-auto">
              <SparklesIcon className="h-6 w-6 text-[#3488fe] mr-3 flex-shrink-0" />
              <p className="text-base md:text-lg text-gray-700 font-medium">
                실시간 피드백 받으면서 연습하고 실전 모의고사까지, 토스 독학으로 끝내자!
              </p>
            </div>
          </div>

          {/* Subscription Status in Hero Section */}
          {user && (
            <div className="max-w-3xl mx-auto bg-white/90 rounded-xl p-6 shadow-sm border border-[#e7f1ff]">
              <h2 className="text-lg font-semibold mb-4 text-gray-800">현재 구독 현황</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="flex items-center space-x-3 bg-blue-50/80 p-4 rounded-lg">
                  <ClockIcon className="h-6 w-6 text-[#3488fe]" />
                  <div>
                    <p className="text-sm text-gray-600">연습 구독권</p>
                    <p className="font-medium">
                      {subscriptionEndDate ? 
                        `${new Date(subscriptionEndDate).toLocaleDateString('ko-KR', { month: 'long', day: 'numeric' })}까지` : 
                        '미구독'
                      }
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-3 bg-blue-50/80 p-4 rounded-lg">
                  <DocumentCheckIcon className="h-6 w-6 text-[#3488fe]" />
                  <div>
                    <p className="text-sm text-gray-600">모의고사 응시권</p>
                    <p className="font-medium">{mockTestCredits}회 남음</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Decorative bottom border */}
        <div className="absolute bottom-0 left-0 right-0">
          <div className="h-px bg-gradient-to-r from-transparent via-blue-200 to-transparent"></div>
          <div className="h-16 bg-gradient-to-b from-blue-50 to-white"></div>
        </div>
      </div>

      {/* Main Section */}
      <div className="bg-white py-12 -mt-16 relative z-10">
        <div className="max-w-6xl mx-auto px-4">
          {/* Tabs */}
          <div className="flex flex-wrap gap-4 mb-8 justify-center">
            <TabButton
              id="combo"
              label="올인원 스파르타 패키지"
              active={activeTab === 'combo'}
              onClick={setActiveTab}
            />
            <TabButton
              id="mock"
              label="모의고사 응시권"
              active={activeTab === 'mock'}
              onClick={setActiveTab}
            />
            <TabButton
              id="practice"
              label="파트별연습/템플릿영작 이용권"
              active={activeTab === 'practice'}
              onClick={setActiveTab}
            />
          </div>

          {/* Content */}
          <div className="bg-white p-8 rounded-xl shadow-md">
            {renderTabContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;