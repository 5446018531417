import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthProvider';
import { ChartBarIcon, ChatBubbleLeftRightIcon, ChevronDownIcon, ChevronUpIcon, SparklesIcon } from '@heroicons/react/24/outline';

const DraftMockResult = ({ onScoreCalculated }) => {
    const { user } = useAuth();
    const userId = user.user_id;
    const mocktestId = window.location.pathname.split('/')[1].replace('mocktest', '');  // Extract mocktest ID from URL
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [mocktestResults, setMocktestResults] = useState(null);
    const [transcriptions, setTranscriptions] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedAnswers, setExpandedAnswers] = useState({});
    const [corrections, setCorrections] = useState({});
    const [loadingCorrections, setLoadingCorrections] = useState({});

    // Helper function to extract the score and reason from the result text
    const extractScoreAndReason = (resultText) => {
        const scoreMatch = resultText.match(/Score: (\d+)/);
        const reasonMatch = resultText.match(/Reason: (.+)/);

        const score = scoreMatch ? parseInt(scoreMatch[1], 10) : 0;
        const reason = reasonMatch ? reasonMatch[1] : "Reason not found";

        return { score, reason };
    };

    // Toggle answer visibility
    const toggleAnswer = (questionId) => {
        setExpandedAnswers(prev => ({
            ...prev,
            [questionId]: !prev[questionId]
        }));
    };

    // Fetch transcriptions
    const fetchTranscriptions = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/mocktest/${mocktestId}/transcriptions`, {
                params: { user_id: userId }
            });
            setTranscriptions(response.data);
        } catch (error) {
            console.error('Error fetching transcriptions:', error);
        }
    };

    // Fetch mock test results from the API and process score/reason
    const fetchMocktestResults = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/get-mocktest-result/${userId}/${mocktestId}`);

            // Extract score and reason from each part's result text
            const results = {
                part2: {
                    questions: [
                        { ...extractScoreAndReason(response.data.part2_q3), questionNumber: 3 },
                        { ...extractScoreAndReason(response.data.part2_q4), questionNumber: 4 }
                    ],
                    title: "그림 묘사",
                    color: "blue"
                },
                part3: {
                    questions: [
                        { ...extractScoreAndReason(response.data.part3_q5), questionNumber: 5 },
                        { ...extractScoreAndReason(response.data.part3_q6), questionNumber: 6 },
                        { ...extractScoreAndReason(response.data.part3_q7), questionNumber: 7 }
                    ],
                    title: "인터뷰 응답",
                    color: "indigo"
                },
                part4: {
                    questions: [
                        { ...extractScoreAndReason(response.data.part4_q8), questionNumber: 8 },
                        { ...extractScoreAndReason(response.data.part4_q9), questionNumber: 9 },
                        { ...extractScoreAndReason(response.data.part4_q10), questionNumber: 10 }
                    ],
                    title: "표를 활용한 질의응답",
                    color: "purple"
                },
                part5: {
                    questions: [
                        { ...extractScoreAndReason(response.data.part5_q11), questionNumber: 11 }
                    ],
                    title: "의견 제시",
                    color: "pink"
                }
            };

            setMocktestResults(results);

            // Calculate the final score using all scores
            const allScores = Object.values(results).flatMap(part => 
                part.questions.map(q => q.score)
            );
            const totalScore = allScores.reduce((acc, score) => acc + score, 0);

            // Add detailed logging
            console.log('Part 2-5 Score Breakdown:');
            Object.entries(results).forEach(([part, data]) => {
                const partScore = data.questions.reduce((acc, q) => acc + q.score, 0);
                console.log(`${part} total score:`, partScore);
                console.log(`${part} questions:`, data.questions.map(q => ({ number: q.questionNumber, score: q.score })));
            });
            console.log('Total score for parts 2-5:', totalScore);

            onScoreCalculated(totalScore);
            setLoading(false);
            setError(null);  // Clear any previous errors
        } catch (error) {
            console.error('Error fetching mock test results:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
                setError(error.response.data.detail || 'Error fetching mock test results.');
            } else {
                setError('Error fetching mock test results.');
            }
            setLoading(false);
        }
    };

    const getCorrection = async (questionNumber, transcription, reason) => {
        if (corrections[questionNumber]) return;
        
        setLoadingCorrections(prev => ({ ...prev, [questionNumber]: true }));
        try {
            const response = await axios.post(`${API_BASE_URL}/api/get-answer-correction`, {
                transcription,
                reason
            });
            setCorrections(prev => ({
                ...prev,
                [questionNumber]: response.data.corrected_answer
            }));
        } catch (error) {
            console.error('Error fetching correction:', error);
        } finally {
            setLoadingCorrections(prev => ({ ...prev, [questionNumber]: false }));
        }
    };

    useEffect(() => {
        Promise.all([fetchMocktestResults(), fetchTranscriptions()]);
    }, []);

    const partColors = {
        part2: {
            bg: 'bg-blue-50',
            text: 'text-blue-700',
            border: 'border-blue-100',
            icon: 'text-blue-600'
        },
        part3: {
            bg: 'bg-indigo-50',
            text: 'text-indigo-700',
            border: 'border-indigo-100',
            icon: 'text-indigo-600'
        },
        part4: {
            bg: 'bg-purple-50',
            text: 'text-purple-700',
            border: 'border-purple-100',
            icon: 'text-purple-600'
        },
        part5: {
            bg: 'bg-pink-50',
            text: 'text-pink-700',
            border: 'border-pink-100',
            icon: 'text-pink-600'
        }
    };

    const getTranscription = (questionNumber) => {
        if (!transcriptions) return null;
        const key = `mock${mocktestId}_q${String(questionNumber).padStart(2, '0')}`;
        return transcriptions[key]?.transcription || null;
    };

    if (loading) return (
        <div className="flex justify-center items-center p-8">
            <div className="text-gray-600">Loading results...</div>
        </div>
    );

    if (error) return (
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-4">
            <div className="text-red-700 font-medium">Error</div>
            <div className="text-red-600">{error}</div>
        </div>
    );

    if (!mocktestResults) return null;

    return (
        <div className="space-y-8">
            {mocktestResults && Object.entries(mocktestResults).map(([partKey, partData]) => (
                <div key={partKey}>
                    <h2 className="text-2xl font-bold text-gray-800 flex items-center mb-4">
                        <ChartBarIcon className={`w-7 h-7 mr-2 ${partColors[partKey].icon}`} />
                        {`Part ${partKey.slice(-1)}: ${partData.title}`}
                    </h2>
                    
                    <div className="bg-white rounded-xl shadow-sm p-6">
                        <div className="grid gap-4">
                            {partData.questions.map((question) => (
                                <div 
                                    key={question.questionNumber} 
                                    className={`${partColors[partKey].bg} rounded-xl p-6`}
                                >
                                    <div className="flex justify-between items-start mb-4">
                                        <div className="flex items-center">
                                            <ChatBubbleLeftRightIcon className={`w-5 h-5 mr-2 ${partColors[partKey].icon}`} />
                                            <h3 className="text-lg font-semibold text-gray-800">
                                                Question {question.questionNumber}
                                            </h3>
                                        </div>
                                        <div className={`${partColors[partKey].text} text-lg font-bold px-3 py-1 rounded-full ${partColors[partKey].bg}`}>
                                            {question.score}점
                                        </div>
                                    </div>
                                    <div className={`${partColors[partKey].text} text-md leading-relaxed font-medium mb-4`}>
                                        {question.reason}
                                    </div>
                                    
                                    {/* Transcription Section */}
                                    <div className="mt-4 border-t border-gray-200 pt-4">
                                        <button
                                            onClick={() => toggleAnswer(question.questionNumber)}
                                            className={`flex items-center justify-between w-full ${partColors[partKey].text} hover:opacity-80 transition-opacity`}
                                        >
                                            <span className="font-medium">내답변 확인하기</span>
                                            {expandedAnswers[question.questionNumber] ? (
                                                <ChevronUpIcon className="w-5 h-5" />
                                            ) : (
                                                <ChevronDownIcon className="w-5 h-5" />
                                            )}
                                        </button>
                                        
                                        {expandedAnswers[question.questionNumber] && (
                                            <div className="mt-3 space-y-4">
                                                <div className="p-4 bg-white rounded-lg border border-gray-200">
                                                    <p className="text-gray-700 whitespace-pre-wrap mb-3">
                                                        {getTranscription(question.questionNumber)}
                                                    </p>
                                                    <div className="flex justify-end">
                                                        <button
                                                            onClick={() => getCorrection(
                                                                question.questionNumber,
                                                                getTranscription(question.questionNumber),
                                                                question.reason
                                                            )}
                                                            className={`flex items-center gap-1 px-3 py-1 rounded-full ${partColors[partKey].text} ${partColors[partKey].bg} hover:opacity-80 transition-opacity`}
                                                            disabled={loadingCorrections[question.questionNumber]}
                                                        >
                                                            <SparklesIcon className="w-4 h-4" />
                                                            <span className="text-sm font-medium">
                                                                내 답변 교정하기
                                                            </span>
                                                        </button>
                                                    </div>
                                                    {corrections[question.questionNumber] && (
                                                        <div className="mt-4 p-3 bg-gray-50 rounded-lg border border-gray-100">
                                                            <p className="text-gray-700 whitespace-pre-wrap">
                                                                {corrections[question.questionNumber]}
                                                            </p>
                                                        </div>
                                                    )}
                                                    {loadingCorrections[question.questionNumber] && (
                                                        <div className="mt-4 flex justify-center">
                                                            <div className="animate-pulse text-gray-500">
                                                                답변 교정 중...
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DraftMockResult;