import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';  // Import useAuth for user authentication
import RecordButton from '../RecordButton';
import LoadingAlert from '../LoadingAlert';

const MockTestPart4Problems = ({ mocktestId }) => {
  const { user } = useAuth();  // Get user info from useAuth
  const [questions, setQuestions] = useState([]);
  const [timer, setTimer] = useState(4); // Initial preparation timer
  const [recording, setRecording] = useState(false);
  const [isQuestionsLoaded, setIsQuestionsLoaded] = useState(false);  // New state to track if questions are loaded
  const navigate = useNavigate();
  const { questionIndex } = useParams(); // Get questionIndex from URL
  const [tableData, setTableData] = useState(null);
  const [notes, setNotes] = useState('');  // New state to hold notes
  const [title, setTitle] = useState('');  // New state to hold table title
  const timerRef = useRef(null);  // Ref to manage timer interval
  const mediaRecorderRef = useRef(null);  // Ref for the media recorder
  const audioChunks = useRef([]); // To store the recorded audio chunks
  const [isLoading, setIsLoading] = useState(false);
  const [subTitle, setSubTitle] = useState('');  // New state to hold table subtitle

  useEffect(() => {
    // Fetch the questions, table title, and notes for Part 4
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mocktest${mocktestId}`)
      .then(response => {
        setQuestions(response.data.part4.questions);
        setTableData(response.data.part4.table);
        setTitle(response.data.part4.table.title);  // Set the title
        setSubTitle(response.data.part4.table.subtitle);  // Set the subtitle
        setNotes(response.data.part4.notes);        // Set the notes
        setIsQuestionsLoaded(true);  // Mark questions as loaded
      })
      .catch(error => console.error("Error fetching questions and table:", error));
  }, [mocktestId, questionIndex]);

  useEffect(() => {
    // Start the preparation countdown when a new question is displayed
      startPreparationCountdown();
    }
  , [questionIndex, questions]);


  const startPreparationCountdown = () => {
    clearInterval(timerRef.current);  // Clear any existing timers
    setTimer(4);  // Set the preparation timer

    timerRef.current = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current);  // Clear the interval when preparation ends
          startRecording();  // Start recording when the preparation time ends
        }
        return prev - 1;
      });
    }, 1000);  // Run the timer every 1 second
  };


  const startRecording = async () => {
    clearInterval(timerRef.current); // Clear the preparation timer
    setRecording(true);
    audioChunks.current = [];  // Clear any previous recordings

    // Start recording via microphone
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);  // Collect the audio chunks
        }
      };

      mediaRecorderRef.current.start();
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
    const answerTime = parseInt(questionIndex, 10) === 2 ? 30 : 15;
    // Start the answer countdown
    setTimer(answerTime); // Answer timer is also 45 seconds
    timerRef.current = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          stopRecording();  // Stop recording when the answer time ends
        }
        return prev - 1;
      });
    }, 1000);
  };


  const stopRecording = () => {
    setRecording(false);

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();  // Stop the media recorder
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }

      mediaRecorderRef.current.onstop = async () => {
          const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
          const formData = new FormData();
          formData.append('mocktest_id', mocktestId);
          formData.append('user_id', user.user_id);  // Get userId from useAuth
          formData.append('question_id', questions[questionIndex].question_id);
          formData.append('audio_file', audioBlob, `${questions[questionIndex].question_id}.wav`);
          formData.append('current_part', 4);  // For Part 3
          formData.append('reference_text', questions[questionIndex].text);  // Reference text

          try {
            setTimer(0);
            clearInterval(timerRef.current);
            setIsLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/submit-answer`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
            if (response) {
              setIsLoading(false);
                // Navigate to the next question or part
                if (parseInt(questionIndex, 10) < questions.length - 1) {
                  // Navigate to the next narrator
                  navigate(`/mocktest${mocktestId}/part4-narrator/${parseInt(questionIndex, 10) + 1}`);
                } else {
                  // Move to the next part if all questions are completed
                  navigate(`/mocktest${mocktestId}/part5-instruction`);
                }
              }
          } catch (error) {
            console.error('Error submitting answer:', error);
          }
      };
    }


  };

  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-start">
      {/* Title with gray background */}
      {isLoading && (
        <LoadingAlert 
          message="답변을 전송 중입니다. 잠시만 기다려주세요."
        />
      )}
      <h1 className="text-xl lg:text-2xl font-bold text-center mb-6 bg-gray-300 w-full py-4">
        Questions {parseInt(questionIndex, 10) + 8} of 11
      </h1>

      {/* Main content area */}
      <div className="bg-white rounded-lg p-6 lg:p-8 w-full max-w-3xl mb-6">
        {/* Display table title if present */}
        {title && (
          <h2 className="text-lg lg:text-xl font-semibold mb-4 text-center">{title}</h2>
        )}

        {/* Display table subtitle if present */}
        {subTitle && (
          <h3 className="text-base lg:text-lg font-semibold mb-4 text-center">{subTitle}</h3>
        )}

        {/* Responsive table container */}
        <div className="w-full overflow-x-auto">
          {/* Display the table with fixed layout */}
          {tableData && (
            <table className="table-auto w-full border-collapse border border-gray-300 table-fixed">
              <thead>
                <tr>
                  {tableData.headers.map((header, index) => (
                    <th key={index} className="border border-gray-300 p-2 text-center text-xs md:text-base lg:text-lg">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex} className="border border-gray-300 p-2 text-center whitespace-normal">
                      {/* Check if the row contains strikethrough content */}
                      {cell.includes("~~") ? (
                        <del>{cell.replace(/~~/g, '')}</del>  // Remove ~~ and wrap with <del> tag
                      ) : (
                        cell  // Normal rendering if no strikethrough
                      )}
                    </td>
                  ))}
                </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* Display notes if present */}
        {notes && (
          <p className="text-sm md:text-lg lg:text-xl italic text-gray-700 mb-6">
            {notes}
          </p>
        )}

        {/* Display the question */}
        <p className="text-base md:text-lg lg:text-xl text-gray-700 mt-6 mb-6">
          {questions.length > 0 ? questions[questionIndex].text : ''}
        </p>

        {/* Display the timer */}
        <p className="text-lg lg:text-xl text-gray-800 mb-6 text-center">
          {recording ? "Recording Time" : "Preparation Time"}: {timer} seconds
        </p>

        {/* Record button */}
        <div className="flex justify-center">
          <RecordButton recording={recording} onStart={startRecording} onStop={stopRecording} />
        </div>
      </div>
    </div>
  );
};

export default MockTestPart4Problems;