import React, { useState, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ArrowLeftIcon, MicrophoneIcon, StopIcon } from '@heroicons/react/24/outline';

const MicrophoneTest = () => {
  const navigate = useNavigate();
  const { mocktestId } = useParams();  // Get mocktestId from URL params
  const [isRecording, setIsRecording] = useState(false);
  const [testCompleted, setTestCompleted] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunks.current = [];
      setIsRecording(true);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
        const url = URL.createObjectURL(audioBlob);
        setAudioUrl(url);
        setTestCompleted(true);
      };

      mediaRecorderRef.current.start();
    } catch (error) {
      console.error("Error accessing microphone:", error);
      alert("마이크 접근에 실패했습니다. 마이크 권한을 확인해주세요.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }
      setIsRecording(false);
    }
  };

  const handleStartTest = () => {
    navigate(`/mocktest${mocktestId}`);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white flex flex-col items-center justify-center p-4">
      <button
        className="absolute top-4 left-4 text-xl"
        onClick={() => navigate('/')}
      >
        <ArrowLeftIcon className="h-6 w-6 text-black" aria-hidden="true" />
      </button>

      <div className="w-full max-w-2xl">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold mb-4">
            마이크 테스트 - 모의고사 {mocktestId}회
          </h1>
          <p className="text-gray-600">
            모의고사를 시작하기 전에 마이크를 테스트해주세요
          </p>
        </div>

        <div className="bg-white p-8 rounded-2xl shadow-lg border border-blue-100">
          {/* Recording Instructions */}
          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-4">녹음 테스트</h2>
            <div className="bg-blue-50 p-4 rounded-lg border border-blue-200">
              <p className="text-lg font-medium text-blue-900 mb-2">다음 문장을 녹음해보세요:</p>
              <p className="text-xl font-bold text-blue-800 italic">
                "I will get my target score on my first try!"
              </p>
            </div>
          </div>

          {/* Recording Controls */}
          <div className="flex flex-col items-center space-y-6">
            {!isRecording ? (
              <button
                onClick={startRecording}
                className="flex items-center justify-center space-x-2 px-8 py-4 bg-[#3488fe] text-white rounded-xl hover:bg-blue-600 transition-all transform hover:scale-105 shadow-md"
              >
                <MicrophoneIcon className="h-6 w-6" />
                <span className="font-medium">녹음 시작하기</span>
              </button>
            ) : (
              <div className="text-center">
                <button
                  onClick={stopRecording}
                  className="flex items-center justify-center space-x-2 px-8 py-4 bg-red-500 text-white rounded-xl hover:bg-red-600 transition-all transform hover:scale-105 shadow-md animate-pulse"
                >
                  <StopIcon className="h-6 w-6" />
                  <span className="font-medium">녹음 중지</span>
                </button>
                <p className="mt-4 text-red-600 font-medium">녹음 중...</p>
              </div>
            )}

            {/* Audio Playback */}
            {audioUrl && (
              <div className="w-full bg-gray-50 p-4 rounded-lg">
                <p className="text-gray-700 mb-2 font-medium">녹음된 음성을 들어보세요:</p>
                <audio src={audioUrl} controls className="w-full" />
              </div>
            )}
          </div>

          {/* Test Completion Section */}
          {testCompleted && (
            <div className="mt-8 border-t border-gray-200 pt-8">
              <p className="text-lg text-center font-medium mb-6">
                녹음된 음성이 잘 들리나요?
              </p>
              <div className="flex justify-center space-x-4">
                <button
                  onClick={handleStartTest}
                  className="px-8 py-4 bg-[#3488fe] text-white rounded-xl hover:bg-blue-600 transition-all transform hover:scale-105 shadow-md"
                >
                  네, 모의고사 시작하기
                </button>
                <button
                  onClick={() => {
                    setTestCompleted(false);
                    setAudioUrl(null);
                  }}
                  className="px-8 py-4 bg-gray-100 text-gray-700 rounded-xl hover:bg-gray-200 transition-all transform hover:scale-105 shadow-md"
                >
                  아니요, 다시 테스트하기
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MicrophoneTest; 