import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { SparklesIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';

const Part2TemplatePracticePeople = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { user } = useAuth(); // Get the authenticated user
  const navigate = useNavigate();

  const [question, setQuestion] = useState(null); // Current question data
  const [recording, setRecording] = useState(false); // Recording state
  const [isProcessing, setIsProcessing] = useState(false); // Add loading state
  const [userAnswer, setUserAnswer] = useState(''); // User's transcribed answer
  const [feedback, setFeedback] = useState(''); // Feedback from AI
  const [progress, setProgress] = useState({ completed: 0, total: 0 }); // User progress
  const [questionId, setQuestionId] = useState(null); // Remove hardcoded value
  const [activeTab, setActiveTab] = useState('aiFeedback'); // To switch between tabs
  const [hasSubscription, setHasSubscription] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const mediaRecorderRef = useRef(null); // Ref for media recorder
  const recordedChunksRef = useRef([]); // To store recorded chunks
  const [userRecordingAudioBlob, setUserRecordingAudioBlob] = useState(null); // Store the recorded audio
  
  const fileName = 'part2_template_people.json'; // JSON file for this practice

  // Fetch initial progress and question when the component mounts
  useEffect(() => {
    if (user) {
      mixpanel.track('Part 2 Template Practice People Page Loaded', {user_id: user.user_id, question_id: questionId});
      
      Promise.all([
        axios.get(`${API_BASE_URL}/api/get-template-progress/${user.user_id}`, {
          params: { file_name: fileName }
        }),
        axios.get(`${API_BASE_URL}/api/landing-data/${user.user_id}`)
      ])
        .then(([progressResponse, landingResponse]) => {
          // Handle progress data
          setProgress({
            completed: progressResponse.data.completed,
            total: progressResponse.data.total,
          });

          // Get completed questions and determine starting question
          const completedQuestions = progressResponse.data.completedQuestions || [];
          let startingQuestionNum = 1;
          
          // Find the last completed question number
          if (completedQuestions.length > 0) {
            const lastCompleted = Math.max(...completedQuestions.map(q => 
              parseInt(q.split('_').pop(), 10)
            ));
            startingQuestionNum = lastCompleted + 1;
            
            // If we've reached the end, stay at the last question
            if (startingQuestionNum > 38) {
              startingQuestionNum = 38;
            }
          }

          // Format the question ID and set it
          const startingQuestionId = `q2_t_people_${startingQuestionNum.toString().padStart(2, '0')}`;
          setQuestionId(startingQuestionId);

          // Handle subscription data
          const { subscriptionEndDate } = landingResponse.data;
          
          let isActive = false;
          if (subscriptionEndDate) {
            const currentDate = new Date().toISOString();
            const endDate = new Date(subscriptionEndDate).toISOString();
            isActive = endDate > currentDate;
          }

          setHasSubscription(isActive);
          
          // Only proceed if user has subscription or it's the first question
          if (isActive || startingQuestionNum === 1) {
            fetchQuestion(startingQuestionId);
          } else {
            setIsModalOpen(true);
          }
        })
        .catch(error => {
          console.error('Error fetching initial data:', error);
        });
    }
  }, [user]);

  // Fetch the current question by its ID
  const fetchQuestion = async (qid) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/get-template-question-by-id/${user.user_id}/${qid}`, {
        params: { file_name: fileName }  // Send file_name as query param
      });
      setQuestion(response.data);
    } catch (error) {
      console.error('Error fetching question:', error);
    }
  };

  // Start recording user input
  const handleStartRecording = async () => {
    try {
      setRecording(true);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      
      // Check for supported MIME types based on browser
      let mimeType = 'audio/webm';
      const supportedMimeTypes = [
        'audio/webm',
        'audio/mp4',
        'audio/ogg',
        'audio/wav'
      ];
      
      // Find the first supported MIME type
      for (const type of supportedMimeTypes) {
        if (MediaRecorder.isTypeSupported(type)) {
          mimeType = type;
          console.log(`Using supported MIME type: ${mimeType}`);
          break;
        }
      }
      
      // Create MediaRecorder with supported MIME type if possible, otherwise without specifying
      let recorder;
      try {
        recorder = new MediaRecorder(stream, { mimeType });
      } catch (e) {
        console.warn(`Failed to create MediaRecorder with MIME type ${mimeType}, using default`);
        recorder = new MediaRecorder(stream);
      }
      
      mediaRecorderRef.current = recorder;
      recordedChunksRef.current = [];
  
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };
  
      mediaRecorderRef.current.start();
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  // Stop recording and transcribe the audio
  const handleStopRecording = async () => {
    setRecording(false);
    setIsProcessing(true); // Start loading

    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    }

    mediaRecorderRef.current.onstop = async () => {
      try {
        // Get the MIME type from the MediaRecorder
        const mimeType = mediaRecorderRef.current.mimeType || 'audio/webm';
        console.log(`Recording completed with MIME type: ${mimeType}`);
        
        // Create blob with the detected MIME type
        const blob = new Blob(recordedChunksRef.current, { type: mimeType });
        setUserRecordingAudioBlob(blob);
        
        // Determine file extension based on MIME type
        let fileExtension = 'webm';
        if (mimeType.includes('mp4')) fileExtension = 'mp4';
        if (mimeType.includes('ogg')) fileExtension = 'ogg';
        if (mimeType.includes('wav')) fileExtension = 'wav';
      
        const formData = new FormData();
        formData.append('file', blob, `recording.${fileExtension}`);
        formData.append('model_answer', question.answer); 
        formData.append('question', question.question);
        
        console.log(`Sending audio file with extension: .${fileExtension}`);
      
        const response = await axios.post(`${API_BASE_URL}/api/template-speech-to-text`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      
        const transcribedText = response.data.transcription;
        setUserAnswer(transcribedText);
        setFeedback(response.data.ai_feedback);
      
      } catch (error) {
        console.error('Error uploading and processing the audio file:', error);
      } finally {
        setIsProcessing(false); // Stop loading regardless of outcome
      }
    };
  };

  // Retry the current question
  const handleRetry = () => {
    setFeedback('');
    setUserAnswer('');
  };

  // Mark the current question as completed and fetch the next one
  const handleNextQuestion = async () => {
    // Check subscription status first
    if (!hasSubscription && questionId === 'q2_t_people_01') {
      setIsModalOpen(true);
      return;
    }

    try {
      await axios.post(`${API_BASE_URL}/api/complete-template-question/${user.user_id}/${'people'}/${questionId}`);
      const nextQuestionId = determineNextQuestionId();
      
      // Check if user can access the next question
      if (!hasSubscription && questionId === 'q2_t_people_01') {
        setIsModalOpen(true);
        return;
      }
      
      setQuestionId(nextQuestionId);
      fetchQuestion(nextQuestionId);
      setFeedback('');
      setUserAnswer('');

      mixpanel.track('Part 2 Template Practice People Question Completed', {question_id: questionId, user_id: user.user_id});
    } catch (error) {
      console.error('Error completing question:', error);
    }
  };

  // Determine the next question ID
    const determineNextQuestionId = () => {
        const currentIdNum = parseInt(questionId.split('_').pop(), 10);
        const nextIdNum = currentIdNum + 1;
        if (nextIdNum > 38) {
        alert("You have reached the last question.");
        return questionId;  // Stay on the current question
        }
        return `q2_t_people_${nextIdNum.toString().padStart(2, '0')}`;
    };

  // Determine the previous question ID
  const determinePreviousQuestionId = () => {
    const currentIdNum = parseInt(questionId.split('_').pop(), 10);
    const prevIdNum = Math.max(1, currentIdNum - 1);  // Ensure the question ID doesn't go below 1
    return `q2_t_people_${prevIdNum.toString().padStart(2, '0')}`;
  };

  const formatAiFeedback = (feedback) => {
    const parts = feedback.split(/([.!?])\s*(?=[A-Za-z])/); // Split by period or exclamation mark
  
    return parts.map((part, index) => {
      // Bold if part is wrapped in ** **
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={`bold-${index}`}>{part.slice(2, -2)}</strong>;
      }
      // Add new line after a period or exclamation mark
      if (part.match(/[.!?]/)) {
        return <span key={`punctuation-${index}`}>{part}<br /></span>;
      }
      return <span key={`text-${index}`}>{part}</span>;
    });
  };

  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
    mixpanel.track('Part 2 Template Practice People Tab Switched', {tab: tab, question_id: questionId});
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Navigation */}
      <div className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-sm border-b border-gray-100 z-10">
        <div className="max-w-7xl mx-auto px-4 h-16 flex items-center">
          <button
            className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
            onClick={() => navigate('/')}
          >
            <ArrowLeftIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="pt-24 sm:pt-28 pb-12 px-4 sm:px-6 lg:px-8 max-w-3xl mx-auto">
        {/* Header Section */}
        <div className="text-center mb-8 sm:mb-12">
          <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4 tracking-tight">
            토스 파트2 <span className="inline-block px-3 py-1 bg-gradient-to-r from-purple-50 via-pink-50 to-blue-50 text-indigo-700 rounded-lg border border-indigo-100/50 shadow-sm hover:shadow-md transition-all duration-300 transform hover:-translate-y-0.5">인물묘사 관련</span> 표현 마스터
          </h1>
          <div className="inline-flex items-center justify-center space-x-2.5 bg-blue-50/80 px-5 py-3 rounded-2xl shadow-sm">
            <SparklesIcon className="h-5 w-5 text-blue-500" />
            <p className="text-sm sm:text-base text-gray-700 font-medium">
              Question {questionId ? parseInt(questionId.split('_').pop(), 10) : 1} / 38
            </p>
          </div>
        </div>

        {/* Question Section */}
        <div className="space-y-6">
          <p className="text-lg font-medium text-gray-700">다음의 문장을 영어로 말해보세요:</p>

          {question ? (
            <div className="bg-white rounded-2xl border border-gray-200/75 p-6 sm:p-8 shadow-sm">
              <p className="text-xl text-gray-900 leading-relaxed">{question.question}</p>
            </div>
          ) : (
            <div className="bg-white rounded-2xl border border-gray-200/75 p-6 sm:p-8 shadow-sm animate-pulse">
              <div className="h-6 bg-gray-200 rounded w-3/4"></div>
            </div>
          )}

          {/* Recording Button */}
          <div className="flex justify-center pt-4">
            <button
              className={`px-6 py-3 text-base rounded-xl font-medium transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-sm hover:shadow ${
                recording
                  ? 'bg-red-500 hover:bg-red-600 text-white focus:ring-red-500'
                  : 'bg-blue-500 hover:bg-blue-600 text-white focus:ring-blue-500'
              }`}
              onClick={recording ? handleStopRecording : userAnswer ? handleRetry : handleStartRecording}
            >
              {recording ? 'Stop Recording' : userAnswer ? 'Retry' : 'Start Recording'}
            </button>
          </div>
        </div>

        {/* Loading Indicator */}
        {isProcessing && (
          <div className="fixed inset-0 bg-black/20 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white rounded-2xl p-8 flex flex-col items-center shadow-xl">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mb-4"></div>
              <p className="text-gray-700 font-medium">답변을 분석하고 있습니다...</p>
            </div>
          </div>
        )}

        {/* Feedback Section */}
        {userAnswer && !isProcessing && (
          <div className="mt-8 space-y-6">
            {/* Tabs */}
            <div className="bg-white rounded-2xl border border-gray-200/75 overflow-hidden">
              <div className="flex border-b border-gray-200">
                <button
                  className={`flex-1 px-4 py-3 text-sm font-medium ${
                    activeTab === 'transcription'
                      ? 'border-b-2 border-blue-500 text-blue-600'
                      : 'text-gray-500 hover:text-gray-700'
                  }`}
                  onClick={() => handleTabSwitch('transcription')}
                >
                  나의 답변
                </button>
                <button
                  className={`flex-1 px-4 py-3 text-sm font-medium flex items-center justify-center ${
                    activeTab === 'aiFeedback'
                      ? 'border-b-2 border-blue-500 text-blue-600'
                      : 'text-gray-500 hover:text-gray-700'
                  }`}
                  onClick={() => handleTabSwitch('aiFeedback')}
                >
                  AI 피드백
                  <SparklesIcon className="w-4 h-4 ml-1.5 text-yellow-500" />
                </button>
                <button
                  className={`flex-1 px-4 py-3 text-sm font-medium ${
                    activeTab === 'modelAnswer'
                      ? 'border-b-2 border-blue-500 text-blue-600'
                      : 'text-gray-500 hover:text-gray-700'
                  }`}
                  onClick={() => handleTabSwitch('modelAnswer')}
                >
                  모범답안
                </button>
              </div>

              <div className="p-6 h-48 overflow-y-auto">
                {activeTab === 'transcription' && (
                  <p className="text-gray-700 leading-relaxed">{userAnswer}</p>
                )}
                {activeTab === 'aiFeedback' && (
                  <div className="text-gray-700 leading-relaxed">{formatAiFeedback(feedback)}</div>
                )}
                {activeTab === 'modelAnswer' && (
                  <p className="text-gray-700 leading-relaxed">
                    {question ? question.answer : 'Loading model answer...'}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Navigation Buttons */}
        {!recording && (
          <div className="flex justify-center gap-4 mt-8">
            <button
              className="px-6 py-3 text-base rounded-xl bg-gray-100 text-gray-700 font-medium hover:bg-gray-200 transition-colors duration-200"
              onClick={() => {
                const previousQuestionId = determinePreviousQuestionId();
                setQuestionId(previousQuestionId);
                fetchQuestion(previousQuestionId);
                setUserAnswer('');
                setFeedback('');
              }}
            >
              Previous Question
            </button>
            <button
              className={`px-6 py-3 text-base rounded-xl font-medium transition-colors duration-200 ${
                userAnswer 
                  ? 'bg-blue-500 text-white hover:bg-blue-600'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
              onClick={userAnswer ? handleNextQuestion : () => {
                // Check subscription status before allowing skip
                if (!hasSubscription && questionId === 'q2_t_people_01') {
                  setIsModalOpen(true);
                  return;
                }
                const nextQuestionId = determineNextQuestionId();
                setQuestionId(nextQuestionId);
                fetchQuestion(nextQuestionId);
                setUserAnswer('');
                setFeedback('');
              }}
            >
              {userAnswer ? 'Next Question' : 'Skip Question'}
            </button>
          </div>
        )}
      </div>

      {/* Subscription Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-2xl p-8 max-w-md w-full mx-4 shadow-xl">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">구독이 필요합니다</h2>
            <p className="text-gray-600 mb-6">더 많은 문제를 풀어보시려면 구독해주세요.</p>
            <button
              onClick={() => navigate('/pricing')}
              className="w-full px-6 py-3 text-base rounded-xl bg-blue-500 text-white font-medium hover:bg-blue-600 transition-colors duration-200"
            >
              구독하기
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Part2TemplatePracticePeople;