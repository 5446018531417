import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '../context/AuthProvider';
import CustomAudioPlayer from '../components/CustomAudioPlayer';
import { ContentContainerBox } from '../components/Container';
import { DoughnutChart, AnswerContainer } from '../components/Part1Components';
import DraftMockResult from './DraftMockResult';
import { SparklesIcon, HomeIcon, AcademicCapIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import useMixpanel from '../hooks/useMixpanel';

const QuestionResult = ({ azureAiScore, mocktestId }) => {
  const { user } = useAuth();
  const [userRecordingDuration, setUserRecordingDuration] = useState(null);
  const [correctAnswerDuration, setCorrectAnswerDuration] = useState(null);

  const getQuestionNumber = () => {
    const questionId = azureAiScore.question_id;
    return questionId.split('_')[1];
  };

  const getUserRecordingUrl = () => {
    const questionNum = getQuestionNumber();
    return `${process.env.REACT_APP_API_BASE_URL}/api/mocktest/user-recording/${user.user_id}/mock${mocktestId}_${questionNum}`;
  };

  const getCorrectAnswerUrl = () => {
    const questionNum = getQuestionNumber();
    return `${process.env.REACT_APP_S3_BASE_URL}/recordings/part1_answers/mock${mocktestId}_${questionNum}.mp3`;
  };

  useEffect(() => {
    const userAudio = new Audio(getUserRecordingUrl());
    userAudio.onloadedmetadata = () => {
      setUserRecordingDuration(userAudio.duration);
    };

    const correctAudio = new Audio(getCorrectAnswerUrl());
    correctAudio.onloadedmetadata = () => {
      setCorrectAnswerDuration(correctAudio.duration);
    };
  }, [azureAiScore.question_id]);

  return (
    <div className="w-full bg-white rounded-xl shadow-sm p-6 mb-6">
      <h3 className="text-xl font-bold text-gray-800 mb-4 flex items-center">
        <ChartBarIcon className="w-6 h-6 mr-2 text-blue-600" />
        Question {getQuestionNumber().slice(-1)}
      </h3>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="bg-gray-50 rounded-xl p-6">
          <div className="flex flex-col items-center">
            <DoughnutChart score={azureAiScore.average_score} />
            <div className="text-md font-sans mt-6 space-y-2">
              <div className="flex justify-between items-center">
                <span className="text-gray-600">유창성:</span>
                <span className="font-semibold">{azureAiScore.fluency_score}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-600">정확성:</span>
                <span className="font-semibold">{azureAiScore.accuracy_score}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-600">억양:</span>
                <span className="font-semibold">{azureAiScore.prosody_score}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-600">완성도:</span>
                <span className="font-semibold">{azureAiScore.completeness_score}</span>
              </div>
              <div className="flex justify-between items-center pt-2 border-t">
                <span className="text-gray-800 font-semibold">평균:</span>
                <span className="font-bold text-blue-600">{azureAiScore.average_score}</span>
              </div>
            </div>
          </div>
        </div>
        
        <AnswerContainer
          userRecordingAudioUrl={getUserRecordingUrl()}
          userRecordingDuration={userRecordingDuration}
          correctAudioUrl={getCorrectAnswerUrl()}
          correctAudioDuration={correctAnswerDuration}
          azureAiScore={azureAiScore}
          questionId={parseInt(getQuestionNumber(), 10)}
          isMockTest={true}
        />
      </div>
    </div>
  );
};

const MockTestCompleted = ({ mocktestId }) => {
  useMixpanel();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [azureResults, setAzureResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [finalScore, setFinalScore] = useState(0);
  const [draftMockScore, setDraftMockScore] = useState(0);
  const [level, setLevel] = useState('');
  const [isDraftMockLoading, setIsDraftMockLoading] = useState(true);

  useEffect(() => {
    mixpanel.track('Mock Test Completed Page Loaded', {
      mocktestId: mocktestId,
      userId: user.user_id
    });

    // Deduct mock test credit
    const deductCredit = async () => {
      try {
        console.log('Attempting to deduct mock test credit for user:', user.user_id);
        const encodedUserId = encodeURIComponent(user.user_id);
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/deduct-mocktest-credit/${encodedUserId}`);
        console.log('Credit deduction successful:', response.data);
      } catch (error) {
        console.error("Error deducting mock test credit:", error.response?.data || error.message);
      }
    };

    deductCredit();
  }, [mocktestId, user.user_id]);

  useEffect(() => {
    const fetchTranscriptions = async () => {
      try {
        const trans = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mocktest/${mocktestId}/transcriptions`, {
          params: { user_id: user.user_id }
        });

        const keysToExtract = [`mock${mocktestId}_q01`, `mock${mocktestId}_q02`];
        const extractedList = keysToExtract.map(key => trans.data[key]).filter(value => value !== undefined);
        setAzureResults(extractedList);
      } catch (error) {
        console.error("Error fetching transcriptions:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user && user.user_id) {
      fetchTranscriptions();
    }
  }, [mocktestId, user.user_id]);

  const calculatePart1Score = (averageScore) => {
    if (averageScore > 85) return 3;
    if (averageScore > 75) return 2;
    if (averageScore > 50) return 1;
    return 0;
  };

  useEffect(() => {
    if (draftMockScore > 0 && azureResults.length > 0) {
      console.log('Calculating final score with draftMockScore:', draftMockScore);
      const part1Scores = azureResults.map(result => calculatePart1Score(result.azure.average_score));
      console.log('Part 1 Azure scores:', azureResults.map(result => result.azure.average_score));
      console.log('Part 1 calculated scores:', part1Scores);
      
      const part1TotalScore = part1Scores.reduce((acc, score) => acc + score, 0);
      console.log('Part 1 total score:', part1TotalScore);
      
      const totalScore = part1TotalScore + draftMockScore;
      console.log('Combined total score (before rounding):', totalScore);
      const totalScore2 = totalScore * 5.714;
      const roundedScore = Math.round(totalScore2 / 10) * 10;
      console.log('Final rounded score:', roundedScore);
      
      setFinalScore(roundedScore);
      calculateLevel(roundedScore);
    }
  }, [draftMockScore, azureResults]);

  const handleDraftMockScoreCalculated = (score) => {
    console.log('Received score from DraftMockResult (parts 2-5):', score);
    setDraftMockScore(score);
    setIsDraftMockLoading(false);
  };

  const calculateLevel = (score) => {
    let calculatedLevel = '';
    if (score >= 200) calculatedLevel = 'AH (Level 8)';
    else if (score >= 190) calculatedLevel = 'AM (Level 8)';
    else if (score >= 180) calculatedLevel = 'AM (Level 7)';
    else if (score >= 160) calculatedLevel = 'AL (Level 7)';
    else if (score >= 150) calculatedLevel = 'IH (Level 6)';
    else if (score >= 140) calculatedLevel = 'IH (Level 6)';
    else if (score >= 130) calculatedLevel = 'IM3 (Level 6)';
    else if (score >= 120) calculatedLevel = 'IM2 (Level 5)';
    else if (score >= 110) calculatedLevel = 'IM1 (Level 5)';
    else if (score >= 100) calculatedLevel = 'IL (Level 4)';
    else if (score >= 60) calculatedLevel = 'NH (Level 3)';
    else if (score >= 30) calculatedLevel = 'NM (Level 2)';
    else calculatedLevel = 'NL (Level 1)';

    setLevel(calculatedLevel);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-xl text-gray-600">Loading results...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      {/* Header Section */}
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <button 
            onClick={() => navigate('/')} 
            className="hover:bg-gray-100 p-2 rounded-full transition-colors"
          >
            <HomeIcon className="w-6 h-6 text-gray-600" />
          </button>
          <h1 className="text-2xl font-bold text-gray-800 flex items-center">
            <AcademicCapIcon className="w-8 h-8 mr-2 text-blue-600" />
            Mock Test Results
          </h1>
          <div className="w-6" /> {/* Spacer for alignment */}
        </div>

        {/* Score Overview */}
        <div className="bg-white rounded-xl shadow-sm p-8 mb-8">
          {isDraftMockLoading ? (
            <div className="text-center py-4">
              <div className="text-xl font-bold text-blue-600 animate-pulse mb-2">
                분석 중입니다...
              </div>
              <div className="text-sm text-gray-500">
                약 1분 정도 소요됩니다
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="text-center">
                <h3 className="text-lg font-medium text-gray-600 mb-3">최종 점수</h3>
                <div className="text-4xl font-bold text-blue-600">{finalScore}</div>
              </div>
              <div className="text-center">
                <h3 className="text-lg font-medium text-gray-600 mb-3">레벨</h3>
                <div className="text-2xl font-semibold text-gray-800">{level}</div>
              </div>
              <div className="text-center">
                <h3 className="text-lg font-medium text-gray-600 mb-3">시험 일자</h3>
                <div className="text-2xl font-semibold text-gray-800">
                  {new Date().toLocaleDateString()}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Part 1 Results */}
        <div className="space-y-6 mb-8">
          <h2 className="text-2xl font-bold text-gray-800 flex items-center">
            <SparklesIcon className="w-7 h-7 mr-2 text-blue-600" />
            Part 1: 읽기 문제
          </h2>
          {azureResults.map((result, index) => (
            <QuestionResult key={index} azureAiScore={result.azure} mocktestId={mocktestId} />
          ))}
        </div>

        {/* Part 2-5 Results */}
        <DraftMockResult onScoreCalculated={handleDraftMockScoreCalculated} />
      </div>
    </div>
  );
};

export default MockTestCompleted;