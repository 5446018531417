import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import RecordButton from '../components/RecordButton';
import { SparklesIcon, HomeIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const PracticePart3 = () => {
  const { user } = useAuth();
  const [narrator, setNarrator] = useState('');
  const [question, setQuestion] = useState('');
  const [questionId, setQuestionId] = useState('');
  const [progress, setProgress] = useState({ completed: 0, total: 54 });
  const [completedQuestions, setCompletedQuestions] = useState([]);
  const [timer, setTimer] = useState(30); // Initial preparation time of 10 seconds
  const [recording, setRecording] = useState(false);
  const [recordingCompleted, setRecordingCompleted] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [aiFeedback, setAiFeedback] = useState('');
  const [activeTab, setActiveTab] = useState('transcription');
  const [hasSubscription, setHasSubscription] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  

  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const timerRef = useRef(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  // Fetch the user's progress and the first question
  useEffect(() => {
    if (user) {
      mixpanel.track('Part 3 Practice Started', { user_id: user.user_id });
      
      Promise.all([
        axios.get(`${API_BASE_URL}/api/get-part3-progress/${user.user_id}`),
        axios.get(`${API_BASE_URL}/api/landing-data/${user.user_id}`)
      ])
        .then(([progressResponse, landingResponse]) => {
          // Handle progress data
          const { completed, total, completedQuestions } = progressResponse.data;
          setProgress({ completed, total });
          setCompletedQuestions(completedQuestions);

          // Handle subscription data
          const { subscriptionEndDate } = landingResponse.data;
          
          let isActive = false;
          if (subscriptionEndDate) {
            const currentDate = new Date().toISOString();
            const endDate = new Date(subscriptionEndDate).toISOString();
            isActive = endDate > currentDate;
          }

          // Set subscription status and THEN fetch the next question
          setHasSubscription(isActive);
          
          // Calculate next question
          const nextQuestionId = completed === 0 ? 'p3_i_001' : `p3_i_${String(completed + 1).padStart(3, '0')}`;
          
          // Pass isActive directly to fetchNextQuestion
          if (isActive || nextQuestionId === 'p3_i_001') {
            fetchNextQuestion(nextQuestionId, isActive);
          } else {
            setIsModalOpen(true);
          }
        })
        .catch(error => {
          console.error("Error fetching initial data:", error);
        });
    }
  }, [user, API_BASE_URL]);

  useEffect(() => {
    // Automatically start recording when preparation timer hits 0 and it's not recording yet
    if (timer === 0 && !recording && !recordingCompleted) {
      startRecordingTimer();  // Automatically start the recording
    }
  
    // Automatically stop recording when the recording timer hits 0
    if (timer === 0 && recording) {
      stopRecording();  // Automatically stop the recording
    }
  }, [timer, recording, recordingCompleted]);

  const fetchNextQuestion = (nextQuestionId, isActiveSubscription = null) => {
    if (!user) return;

    // Use passed isActiveSubscription if available, otherwise use state
    const hasAccess = isActiveSubscription !== null ? isActiveSubscription : hasSubscription;

    // Check subscription status
    if (!hasAccess && nextQuestionId !== 'p3_i_001') {
      setIsModalOpen(true);
      return;
    }

    axios.get(`${API_BASE_URL}/api/get-part3-question-by-id/${nextQuestionId}`)
      .then((response) => {
        const questionData = response.data;
        setNarrator(questionData.narrator);
        setQuestion(questionData.question.question);
        setQuestionId(nextQuestionId);
        resetStateForNextQuestion();
        startCountdown(30);
      })
      .catch(error => {
        console.error("Error fetching next question:", error);
      });
  };

  const resetStateForNextQuestion = () => {
    clearInterval(timerRef.current);
    setTimer(30);  // Reset preparation timer to 10 seconds
    setRecording(false);
    setRecordingCompleted(false);
    setTranscription('');
    setAiFeedback('');
    setActiveTab('transcription');
  };

  const startCountdown = (duration) => {
    setTimer(duration);
    clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setTimer(prev => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          if (recording) {
            stopRecording();  // Automatically stop recording when time runs out
          }
          return 0;
        }
        return prev - 1;
      });
    }, 1500);
  };

  const startRecordingTimer = () => {
    clearInterval(timerRef.current);
    setTimer(30);  // Set recording timer to 30 seconds
    setRecording(true);
    setRecordingCompleted(false);
    startCountdown(15);  // Start the 30-second recording timer
    handleStartRecording();
    mixpanel.track('Part 3 Recording Started', { user_id: user.user_id, question_id: questionId });
  };

  const handleStartRecording = async () => {
    try {
      const permissionResult = await navigator.permissions.query({ name: 'microphone' });
      
      if (permissionResult.state === 'denied') {
        showError("마이크 접근이 차단되어있습니다. 브라우저 설정에서 마이크 권한을 허용해주세요.");
        return;
      }
    } catch (err) {
      // Silently continue if permission check fails
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Log supported MIME types
      const supportedTypes = [
        'audio/webm',
        'audio/wav',
        'audio/mp4',
        'audio/ogg',
        'audio/mpeg'
      ].filter(type => MediaRecorder.isTypeSupported(type));
      
      // Use the first supported type, with fallback for Safari
      let mimeType;
      if (supportedTypes.includes('audio/wav')) {
        mimeType = 'audio/wav';
      } else if (supportedTypes.includes('audio/mp4')) {
        mimeType = 'audio/mp4';  // Safari often supports this
      } else {
        mimeType = supportedTypes[0];
      }

      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: mimeType,
        audioBitsPerSecond: 128000
      });
      recordedChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.start(1000); // Get data every second

    } catch (err) {
      console.error("Error in recording process:", err);
      
      if (err.name === 'NotAllowedError') {
        showError("마이크 접근이 거부되었습니다. 마이크 권한을 허용해주세요.");
      } else if (err.name === 'NotFoundError') {
        showError("마이크를 찾을 수 없습니다. 마이크가 연결되어 있는지 확인해주세요.");
      } else {
        showError("녹음을 시작할 수 없습니다. 마이크 연결을 확인해주세요.");
      }
      setRecording(false);
      clearInterval(timerRef.current);
    }
  };

  const stopRecording = () => {
    clearInterval(timerRef.current);
    setRecording(false);  // Mark recording as stopped
    setTimer(0);  // Reset timer to 0

    handleStopRecording();
    setRecordingCompleted(true);  // Set recording as completed
    mixpanel.track('Part 3 Recording Stopped', { user_id: user.user_id, question_id: questionId });

  };

  const handleStopRecording = () => {
    if (!mediaRecorderRef.current || mediaRecorderRef.current.state === 'inactive') {
      console.error("MediaRecorder not initialized or inactive");
      showError("녹음이 제대로 초기화되지 않았습니다. 다시 시도해주세요.");
      return;
    }

    mediaRecorderRef.current.requestData(); // Request any final chunks
    mediaRecorderRef.current.stop();

    const stream = mediaRecorderRef.current.stream;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
    }

    mediaRecorderRef.current.onstop = async () => {
      // Check for empty recording
      if (!recordedChunksRef.current.length) {
        console.error("No recorded chunks available!");
        showError("녹음된 음성이 없습니다. 마이크가 제대로 작동하는지 확인 후 다시 시도해주세요.");
        return;
      }

      // Check for very small recording
      const totalSize = recordedChunksRef.current.reduce((acc, chunk) => acc + chunk.size, 0);
      if (totalSize < 1000) {
        console.error("Recording too small:", totalSize, "bytes");
        showError("녹음된 음성이 너무 짧거나 비어있습니다. 다시 시도해주세요.");
        return;
      }

      const blob = new Blob(recordedChunksRef.current, { type: 'audio/webm' });
      const file = new File([blob], 'recording.webm', { type: 'audio/webm' });

      if (blob.size < 1000) {
        console.error("Blob too small:", blob.size, "bytes");
        showError("녹음된 음성이 너무 짧거나 비어있습니다. 다시 시도해주세요.");
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('model_answer', question);

      try {
        const response = await axios.post(`${API_BASE_URL}/api/part5-speech-to-text`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        // Check if the transcription is in English
        if (!isEnglish(response.data.transcription)) {
          showError("답변은 영어로 해주세요. Please answer in English.");
          return;
        }

        setTranscription(response.data.transcription);
        setAiFeedback(response.data.ai_feedback);
      } catch (error) {
        console.error("Error processing audio:", error);
        showError("음성 처리 중 오류가 발생했습니다. 다시 시도해주세요.");
      }
    };

    mediaRecorderRef.current = null;
  };

  // Add isEnglish helper function
  const isEnglish = (text) => {
    const cleanText = text.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\d]/g, "");
    const words = cleanText.trim().split(/\s+/);
    const englishWords = words.filter(word => /^[a-zA-Z]+$/.test(word));
    return englishWords.length / words.length >= 0.7;
  };

  // Add error handling functions
  const showError = (message) => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }
    }
    
    clearInterval(timerRef.current);
    setErrorMessage(message);
    setShowErrorModal(true);
  };

  const handleErrorModalClose = () => {
    setShowErrorModal(false);
    
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }
    }
    mediaRecorderRef.current = null;
    
    setRecording(false);
    setRecordingCompleted(false);
    recordedChunksRef.current = [];
    setTranscription('');
    setAiFeedback('');
    
    clearInterval(timerRef.current);
    setTimer(30);
    startCountdown(30);
  };

  // Add ErrorModal component
  const ErrorModal = ({ message, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4 relative">
        <div className="text-center">
          <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-blue-50 mb-4">
            <svg className="h-10 w-10 text-[#3488fe]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          
          <h3 className="text-xl font-medium text-gray-900 mb-4">
            알림
          </h3>
          
          <p className="text-base text-gray-600 mb-6">
            {message}
          </p>
          
          <div className="mt-5">
            <button
              onClick={onClose}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-[#3488fe] text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm transition-colors duration-200"
            >
              다시 시도하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const handleNextQuestion = () => {
    if (!user || !questionId) return;

    // Get fresh subscription status from server
    axios.get(`${API_BASE_URL}/api/landing-data/${user.user_id}`)
      .then((response) => {
        const { subscriptionEndDate } = response.data;
        const currentDate = new Date().toISOString();
        const endDate = new Date(subscriptionEndDate).toISOString();
        const isActive = endDate > currentDate;

        if (isActive) {
          axios.post(`${API_BASE_URL}/api/complete-part3-question/${user.user_id}/${questionId}`)
            .then(() => {
              setProgress(prev => ({
                ...prev,
                completed: prev.completed + 1,
              }));
              const nextIdNumber = parseInt(questionId.split('_').pop()) + 1;
              const nextQuestionId = `p3_i_${String(nextIdNumber).padStart(3, '0')}`;
              
              // Pass isActive directly to fetchNextQuestion
              fetchNextQuestion(nextQuestionId, isActive);

              mixpanel.track('Part 3 Question Completed', { user_id: user.user_id, question_id: questionId });
            })
            .catch(error => {
              console.error("Error completing question:", error);
            });
        } else {
          setIsModalOpen(true);
        }
      })
      .catch(error => {
        console.error("Error checking subscription:", error);
      });
  };

  const formatAiFeedback = (feedback) => {
    // Split by numbered points, bullet point dashes (followed by space), and bold text
    const parts = feedback.split(/(\d+\.\s|-\s|\*\*.*?\*\*)/);
  
    return parts.map((part, index, array) => {
      // Bold if part is wrapped in ** **
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      }
      
      // Add a new line before numbers with periods or dashes with spaces ONLY if it's not at the start
      if (part.match(/\d+\.\s|-\s/)) {  // Note: -\s means dash followed by whitespace
        // Check if this is effectively the start of the content
        const previousContent = array.slice(0, index).join('').trim();
        if (previousContent) {
          return <><br key={index} />{part}</>;
        }
        return part;
      }
      return part;
    });
  };

  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
    mixpanel.track('Part 3 Feedback Tab Clicked', { user_id: user.user_id, tab });
  };

  const fetchQuestionById = (id) => {
    const questionId = `p3_i_${String(id).padStart(3, '0')}`;
    fetchNextQuestion(questionId);
  };

  const progressPercentage = (completedQuestions.length / progress.total) * 100;

  const handleDropdownChange = (event) => {
    const selectedQuestionId = parseInt(event.target.value);
    if (selectedQuestionId <= progress.completed) {
      fetchQuestionById(selectedQuestionId);
    }
  };

  const handleHomeClick = () => {
    navigate('/'); // Redirect to the landing page
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-start p-4">
      <div className="w-full max-w-4xl mx-auto"> {/* Add margins for large screens */}
        {/* Top Row: Home Button and Title */}
        <div className="flex justify-between items-center mb-4">
          <button className="text-base sm:text-lg" onClick={handleHomeClick}>
            <HomeIcon className="w-6 h-6" />
          </button>
          <h1 className="text-base sm:text-lg font-bold">Part 3 연습</h1>
          <div className="w-24"></div> {/* Placeholder for alignment */}
        </div>

        {/* Dropdown for Questions */}
        <div className="mb-4">
          <select
            className="border rounded p-2 w-full"
            value={parseInt(questionId.split('_')[2])}  // Correctly extract the current question number
            onChange={handleDropdownChange}
          >
            {Array.from({ length: progress.completed + 1 }, (_, i) => i + 1).map((number) => (
              <option key={number} value={number}>
                Question {number}
              </option>
            ))}
          </select>
        </div>

        {/* Progress Bar */}
        <div className="mb-4">
          <p className="text-base sm:text-lg">진행상황</p>
          <div className="relative w-full h-4 bg-gray-300 rounded">
            <div
              className="absolute h-full bg-[#009963] rounded"
              style={{ width: `${progressPercentage}%` }}
            ></div>

            <div
              className="absolute top-6 text-sm sm:text-base"
              style={{ left: `calc(${progressPercentage}% - 10px)` }} // Adjust the position
            >
              {completedQuestions.length}
            </div>
          </div>

          <div className="flex justify-between mt-2 text-sm sm:text-base">
            <span></span>
            <span>{progress.total}</span>
          </div>
        </div>

        {/* Narrator and Question Display */}
        <div className="mb-8 space-y-6">
          {/* Narrator section with subtle styling */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <p className="text-base text-gray-600 mb-1">Narrator</p>
            <p className="text-base md:text-lg text-gray-800">{narrator}</p>
          </div>
          
          {/* Question section with prominent styling */}
          <div className="bg-white p-6 rounded-lg shadow-md border-l-4 border-[#3488fe]">
            <p className="text-base text-[#3488fe] mb-2 font-medium">Question</p>
            <p className="text-lg md:text-xl font-medium text-gray-900">{question}</p>
          </div>
        </div>

        {/* Timer and Recording Button */}
        <div className="mt-4 flex flex-col items-center">
          <p className="text-lg mb-8">Time left: {timer}초</p>
          <RecordButton
            recording={recording}
            onStart={startRecordingTimer}
            onStop={stopRecording}
            buttonText={recording ? 'Stop Recording' : (recordingCompleted ? 'Re-try' : 'Start Recording')}
          />
        </div>

        {/* Tabs for '나의 답변' and 'AI 피드백' */}
        {transcription && (
          <div className="mt-6">
            <div className="flex justify-between border-b-2 border-[#E5E8EB]">
              <div
                className={`cursor-pointer text-lg w-1/2 flex justify-center ${activeTab === 'transcription' ? 'border-b-4 border-[#009963] text-black' : 'border-none'}`}
                onClick={() => handleTabSwitch('transcription')}
              >
                나의 답변
              </div>
              <div
                className={`cursor-pointer text-lg w-1/2 flex justify-center items-center ${activeTab === 'aiFeedback' ? 'border-b-4 border-[#009963] text-black' : 'border-none'}`}
                onClick={() => handleTabSwitch('aiFeedback')}
              >
                AI 피드백
                <SparklesIcon className="w-5 h-5 ml-2 text-yellow-500" />
              </div>
            </div>

            {/* Tab Content */}
            {activeTab === 'transcription' && (
              <div className="mt-4 p-4 bg-white shadow-lg rounded-lg">
                <p>{transcription}</p>
              </div>
            )}

            {activeTab === 'aiFeedback' && (
              <div className="mt-4 p-4 bg-white shadow-lg rounded-lg">
                <p>{formatAiFeedback(aiFeedback)}</p>
              </div>
            )}

            {/* Next Question Button */}
            <button
              onClick={handleNextQuestion}
              className="px-6 py-3 mt-6 w-full rounded-lg bg-[#F5F0E5] text-black text-base sm:text-lg"
            >
              Next Question
            </button>
          </div>
        )}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-md">
            <h2 className="text-xl font-bold mb-4">구독이 필요합니다</h2>
            <p className="mb-4">더 많은 문제를 풀어보시려면 구독해주세요.</p>
            <button
              onClick={() => navigate('/pricing')}
              className="w-full bg-[#3488fe] text-white py-2 rounded-lg"
            >
              구독하기
            </button>
          </div>
        </div>
      )}

      {/* Add Error Modal */}
      {showErrorModal && (
        <ErrorModal
          message={errorMessage}
          onClose={handleErrorModalClose}
        />
      )}
    </div>
  );
};

export default PracticePart3;