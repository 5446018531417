import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import axios from 'axios';

const PaymentComplete = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [productName, setProductName] = useState('');

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        // Get payment parameters from URL
        const searchParams = new URLSearchParams(location.search);
        const imp_uid = searchParams.get('imp_uid');
        const merchant_uid = searchParams.get('merchant_uid');
        const imp_success = searchParams.get('imp_success');

        // Check if payment was marked as successful by payment gateway
        if (imp_success !== 'true') {
          setIsSuccess(false);
          setErrorMessage('결제가 취소되었거나 실패했습니다.');
          setIsLoading(false);
          return;
        }

        if (!user || !user.user_id) {
          setIsSuccess(false);
          setErrorMessage('사용자 정보를 찾을 수 없습니다. 다시 로그인해주세요.');
          setIsLoading(false);
          return;
        }

        // Get the current token
        const token = localStorage.getItem('token');
        if (!token) {
          setIsSuccess(false);
          setErrorMessage('로그인이 필요합니다. 다시 로그인해주세요.');
          setIsLoading(false);
          return;
        }

        // Verify the payment with the backend
        const verifyResponse = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/payments/verify-mobile`,
          {
            imp_uid,
            merchant_uid,
            user_id: user.user_id
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          }
        );

        // Check response
        const result = verifyResponse.data;
        
        if (result.success) {
          setIsSuccess(true);
          setProductName(result.product_name || '구독 상품');
        } else {
          setIsSuccess(false);
          setErrorMessage(result.message || '결제 확인에 실패했습니다.');
        }
      } catch (error) {
        console.error('Payment verification error:', error);
        setIsSuccess(false);
        setErrorMessage(error.response?.data?.detail || '결제 확인 중 오류가 발생했습니다.');
      } finally {
        setIsLoading(false);
      }
    };

    verifyPayment();
  }, [location.search, user, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg overflow-hidden">
        {isLoading ? (
          <div className="p-8 text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#3488fe] mx-auto mb-4"></div>
            <h2 className="text-xl font-semibold text-gray-800 mb-2">결제 확인 중...</h2>
            <p className="text-gray-600">잠시만 기다려주세요.</p>
          </div>
        ) : isSuccess ? (
          <div className="p-8 text-center">
            <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
              <CheckCircleIcon className="h-10 w-10 text-green-500" />
            </div>
            <h2 className="text-xl font-semibold text-gray-800 mb-2">결제가 완료되었습니다!</h2>
            <p className="text-gray-600 mb-8">{productName} 구매에 성공했습니다.</p>
            <button
              onClick={() => navigate('/')}
              className="w-full bg-[#3488fe] text-white py-3 rounded-lg hover:bg-blue-600 transition-colors font-medium"
            >
              홈으로 돌아가기
            </button>
          </div>
        ) : (
          <div className="p-8 text-center">
            <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-4">
              <XCircleIcon className="h-10 w-10 text-red-500" />
            </div>
            <h2 className="text-xl font-semibold text-gray-800 mb-2">결제 확인 실패</h2>
            <p className="text-gray-600 mb-8">{errorMessage}</p>
            <div className="space-y-4">
              <button
                onClick={() => navigate('/pricing')}
                className="w-full bg-[#3488fe] text-white py-3 rounded-lg hover:bg-blue-600 transition-colors font-medium"
              >
                결제 페이지로 돌아가기
              </button>
              <button
                onClick={() => navigate('/')}
                className="w-full bg-gray-200 text-gray-700 py-3 rounded-lg hover:bg-gray-300 transition-colors font-medium"
              >
                홈으로 돌아가기
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentComplete;