import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import RecordButton from '../RecordButton';  // A component for starting/stopping recording
import { useAuth } from '../../context/AuthProvider';
import LoadingAlert from '../LoadingAlert';

const MockTestPart5Problems = ({ mocktestId }) => {
  const { user } = useAuth();  // Get the authenticated user ID
  const [question, setQuestion] = useState('');
  const [timer, setTimer] = useState(45); // Initial preparation timer
  const [recording, setRecording] = useState(false);
  const navigate = useNavigate();
  const mediaRecorderRef = useRef(null);  // Ref for the media recorder
  const audioChunks = useRef([]); // To store the recorded audio chunks
  const timerRef = useRef(null);  // Ref for managing the timer interval
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch the single question for Part 5
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mocktest${mocktestId}`)
      .then(response => setQuestion(response.data.part5.questions[0].text))
      .catch(error => console.error("Error fetching question for Part 5:", error));
    
    startPreparationCountdown(); // Start the preparation countdown after fetching the question
  }, [mocktestId]);

  const startPreparationCountdown = () => {
    clearInterval(timerRef.current); // Clear any existing intervals
    setTimer(45);  // Set preparation time
    timerRef.current = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current);  // Stop the timer at 0
          startRecording();  // Start recording after preparation
        }
        return prev - 1;
      });
    }, 1000);  // Run the timer every 1 second
  };

  const startRecording = async () => {
    clearInterval(timerRef.current); // Clear any previous interval
    setRecording(true);
    audioChunks.current = [];  // Clear previous audio chunks

    // Start recording via microphone
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);  // Collect the audio chunks
        }
      };

      mediaRecorderRef.current.start();
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }

    setTimer(60);  // Set answer time for Part 5 to 60 seconds
    timerRef.current = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current);  // Stop the timer at 0
          stopRecording();  // Stop recording after the answer time
        }
        return prev - 1;
      });
    }, 1000);
  };

  const stopRecording = () => {
    setRecording(false);

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop(); // Stop the media recorder
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
        const formData = new FormData();
        formData.append('mocktest_id', mocktestId);
        formData.append('user_id', user.user_id);  // Append userId from useAuth
        formData.append('question_id', `mock${mocktestId}_q11`);  // Dynamic mocktest ID
        formData.append('audio_file', audioBlob, `mock${mocktestId}_q11.wav`);  // Dynamic mocktest ID
        formData.append('current_part', 5);  // Specify part 5
        formData.append('reference_text', question);  // Reference text for the question
        try {
          setTimer(0);
          clearInterval(timerRef.current);
          setIsLoading(true);
          const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/submit-answer`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          console.log('Answer submitted successfully:');
          if (response) {
            setIsLoading(false);
            navigate(`/mocktest${mocktestId}/completed`);
          }
        } catch (error) {
          console.error('Error submitting answer:', error);
        }

       
      };
    }
  };

  // Cleanup timer when component unmounts
  useEffect(() => {
    return () => clearInterval(timerRef.current);
  }, []);

  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-start">
      {/* Title with gray background */}
      {isLoading && (
        <LoadingAlert 
          message="답변을 전송 중입니다. 잠시만 기다려주세요."
        />
      )}
      <h1 className="text-xl lg:text-2xl font-bold text-center mb-6 bg-gray-300 w-full py-4">
        Question 11 of 11
      </h1>

      {/* Main content area */}
      <div className="bg-white rounded-lg p-6 lg:p-8 w-full max-w-3xl mb-6">
        <p className="text-lg md:text-xl lg:text-2xl text-gray-700 mb-6 whitespace-pre-line">
          {question.replace(/(\d+\.)/g, '\n$1').trim() + '\n'}
        </p>

        {/* Display the timer */}
        <p className="text-lg lg:text-xl text-gray-800 mb-6 text-center">
          {recording ? "Recording Time" : "Preparation Time"}: {timer} seconds
        </p>

        {/* Record button */}
        <div className="flex justify-center">
          <RecordButton recording={recording} onStart={startRecording} onStop={stopRecording} />
        </div>
      </div>
    </div>
  );
};

export default MockTestPart5Problems;