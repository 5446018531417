import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';  // Use useAuth to get user info
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { HomeIcon, ClipboardCheckIcon, DocumentTextIcon, FolderIcon, PencilIcon, AcademicCapIcon, CheckCircleIcon, ChartBarIcon, SpeakerphoneIcon, CurrencyDollarIcon, DocumentIcon } from '@heroicons/react/24/outline';

const LandingPage = () => {
  const { user, logOut } = useAuth();  // Get user and logOut from context
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState(0);  // Default value for countdown days
  const [completedQuestions, setCompletedQuestions] = useState(0);  // For counting completed questions
  const [partProgress, setPartProgress] = useState({});  // For tracking progress on each part
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);  // Control logout button visibility
  const [freeCredits, setFreeCredits] = useState(0);  // Default value for free credits
  const [mockTestCredits, setMockTestCredits] = useState(0);  // Default value for mock test credits
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const navigate = useNavigate();

  const dropdownRef = useRef(null);
  const logoutRef = useRef(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleNavigate = (path) => {
    navigate(path);
  }

  // Fetch user-specific landing page data from the backend
  useEffect(() => {
    if (user) {
      axios.get(`${API_BASE_URL}/api/landing-data/${user.user_id}`)
        .then((response) => {
          const { days_until_exam, completed_questions_count, part_progress, freeCredits, mockTestCredits, subscriptionEndDate } = response.data;
          if (days_until_exam !== undefined) {
            setSelectedDays(days_until_exam);
          }
          if (completed_questions_count !== undefined) {
            setCompletedQuestions(completed_questions_count);
          }
          if (part_progress) {
            setPartProgress(part_progress);
          }
          if (freeCredits !== undefined) {
            setFreeCredits(freeCredits);
          }
          if (mockTestCredits !== undefined) {
            setMockTestCredits(mockTestCredits);
          }
          if (subscriptionEndDate) {
            setSubscriptionEndDate(subscriptionEndDate);
          }
        })
        .catch((error) => {
          if (error.response) {
            // Handle error silently or add appropriate error handling
          }
        });
    }
  }, [user, API_BASE_URL]);

  // Handle clicks outside of dropdown and logout button
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);  // Close the dropdown if clicked outside
      }
      if (logoutRef.current && !logoutRef.current.contains(event.target)) {
        setIsLogoutVisible(false);  // Close the logout button if clicked outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Function to handle login or navigate to the login page
  const handleLoginClick = () => {
    if (user) {
      // Navigate directly to profile instead of showing dropdown
      navigate('/my-profile');
    } else {
      navigate('/login');
    }
  };

  // Function to handle logout
  const handleLogoutClick = () => {
    logOut();  // Call the logOut function from Auth context
    navigate('/login');  // Navigate to login page after logout
  };

  // Function to navigate to My Profile page
  const handleProfileClick = () => {
    navigate('/my-profile');  // Navigate to the My Profile page
  };

  // Function to navigate to a protected route or redirect to login
  const handleProtectedClick = (path) => {
    if (user) {
      navigate(path);
    } else {
      navigate('/login');  // Redirect to login page if user is not logged in
    }
  };

  // Function to toggle dropdown for days selection
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Function to select a specific day from the dropdown
  const selectDays = (day) => {
    setSelectedDays(day);
    setIsDropdownOpen(false);
  };

  // const partTitles = [
  //   'Read a text aloud',
  //   'Describe a picture',
  //   'Respond to questions',
  //   'Respond to questions using information provided',
  //   'Express an opinion'
  // ];
  const partTitles = [
    '지문 따라읽기',
    '사진 묘사하기',
    '질문에 대답하기',
    '표 정보 활용하여 답하기',
    '의견 표현하기'
  ];

  // Function to show alert when clicking on the mock test start button
  const handleMockTestClick = (testNumber) => {
    alert(`You clicked on Mock Test ${testNumber}`);
  };

  // Function to handle button click and track event
  const handleButtonClick = () => {
    // Track a custom event in Mixpanel
    mixpanel.track('Landing Page Button Click', {
      button_name: '배토스 피드백 구글 폼',
      user_id: user ? user.user_id : 'guest'  // Add user_id if user is logged in
    });

    // Redirect user to Google Forms
    window.location.href = 'https://forms.gle/JKYYB7r3eaXCdZuBA';
  };

  // New function to check if subscription is active
  const isSubscriptionActive = () => {
    if (!subscriptionEndDate) return false;
    return new Date(subscriptionEndDate) > new Date();
  };

  // Simplified handler for practice sections - only checks login
  const handlePracticeStart = (path) => {
    if (!user) {
      navigate('/login');
      return;
    }
    navigate(path);
  };

  // Keep the mock test handler as is since it uses different credits
  const handleMockTestStart = (mocktestId) => {
    if (!user) {
      navigate('/login');
      return;
    }
    
    if (mockTestCredits > 0) {
      navigate(`/mocktest-mic-test/${mocktestId}`);
    } else {
      setModalMessage('모의고사');
      setIsModalOpen(true);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center">
      {/* Top navigation bar for large screens */}
      <div className="w-full flex justify-between items-center px-4 py-2 bg-[#f9fafc] relative">
        {/* Left side - Logo */}
        <div className="flex items-center">
          <img 
            src="/bae-logo.svg" 
            alt="BaeTos Logo" 
            className="h-8 w-8 cursor-pointer"
            onClick={() => handleNavigate('/')}
          />
          {/* <span className="ml-2 text-lg font-semibold text-gray-800 cursor-pointer" onClick={() => handleNavigate('/')}>
            BaeTalk
          </span> */}
        </div>

        {/* Right-aligned navigation buttons and login button */}
        <div className="flex space-x-4 items-center">
          {/* Navigation Buttons for large screens - COMMENTED OUT
          <button
            className="hidden md:inline-block px-2 py-2 rounded-lg hover:bg-[#f3f4f6]"
            onClick={() => handleNavigate('/')}
          >
            파트별 문제은행
          </button>
          <button
            className="hidden md:inline-block px-2 py-2 rounded-lg hover:bg-[#f3f4f6]"
            onClick={() => handleNavigate('/template-practice')}
          >
            템플릿 영작연습
          </button>
          <button
            className="hidden md:inline-block px-2 py-2 rounded-lg hover:bg-[#f3f4f6]"
            onClick={() => handleNavigate('/mock-test')}
          >
            실전 모의고사
          </button>
          */}
          
          {/* Pricing button with hover information */}
          <div className="relative group">
            <button
              className="px-4 py-2 rounded-lg text-white text-xs md:text-base bg-[#ff6b6b] hover:bg-[#ff5252]"
              onClick={() => handleNavigate('/pricing')}
            >
              이용권 구매
            </button>
            
            {/* Hover tooltip with subscription info - only show when user is logged in */}
            {user && (
              <div className="absolute invisible group-hover:visible bg-gray-800 text-white text-sm rounded-lg py-2 px-3 -bottom-20 left-1/2 transform -translate-x-1/2 whitespace-nowrap z-50">
                <div className="mb-1">
                  {subscriptionEndDate ? 
                    `연습 구독권: ${new Date(subscriptionEndDate).toLocaleDateString('ko-KR', { month: 'long', day: 'numeric' })}까지` : 
                    '연습 구독권: 미구독'
                  }
                </div>
                <div>
                  모의고사 응시권: {mockTestCredits}회
                </div>
              </div>
            )}
          </div>

          <button
            className="px-4 py-2 rounded-lg text-xs md:text-base"
            style={{ backgroundColor: '#3488fe', color: '#ffffff' }}
            onClick={handleLoginClick}
          >
            {user ? `반가워요, ${user.name}!` : 'Login'}
          </button>
        </div>

        {/* Show profile button when user is logged in and the dropdown is open */}
        {isLogoutVisible && (
          <button
            ref={logoutRef}
            className="absolute top-12 right-4 mt-2 px-4 py-2 rounded-lg bg-[#3488fe] text-white z-50"
            onClick={handleProfileClick}
          >
            내 프로필
          </button>
        )}
      </div>

      {/* Promotional text and Feedback Button in the same row container */}
      <div className="w-full max-w-3xl px-4 flex flex-col md:flex-row justify-between items-start md:items-center mt-8 space-y-4 md:space-y-0">
        {/* Left side: Promotional text */}
        <div className="w-full">
          <h1 className="text-2xl font-bold">파트별 문제은행과 실전 모의고사로 토스 한 번에 끝내자!</h1>
          <p className="mt-2 text-base font-bold">
            <span className="bg-[#bedbfb] px-1">
              2025년 최신경향 & AI 맞춤형 실시간 피드백 & 모범답안 제공!
            </span>
          </p>
          
          <hr className="my-4 border-t border-gray-300" />
          
          <p className="mt-2 text-base font-semibold text-[#3488fe]">
          🌟 지금 회원가입하면 무료 체험권 증정 🌟
          </p>
          
          <ul className="mt-2 text-base space-y-2">
          
          <li>• 토익스피킹 실전모의고사 + 파트별 문제은행 + 초보를 위한 템플릿 영작연습 🔥</li>
            <li>• 직접 말해보며 연습하면, 토스 전문 AI가 즉각 피드백을!</li>
            {/* <li>• 배토스에서 충분히 내배트면서 연습하고 원하는 점수 한 번에 GET하자!</li> */}
          </ul>

          <p className="mt-4 text-base">
            <span className="text-gray-600">#취준 #승진 배토스가 응원합니다!</span>
            <br />
            
          </p>
        </div>

        {/* Right side: Feedback Button */}
        {/* <div className="w-full md:w-1/2 flex md:justify-end justify-start">
        <button
            className="px-6 py-3 rounded-lg text-white"
            style={{ backgroundColor: '#009963' }}
            onClick={handleButtonClick}  // Attach event tracking to button click
          >
            피드백 남기고 무료 실전 토스모의고사 체험받기!
          </button>
        </div> */}
      </div>

      {/* Event Countdown and Completed Count in Rounded Boxes */}
      {user && (  // Only render if user is logged in
        <div className="flex justify-center mt-6 space-x-4">
          {/* Dropdown for countdown */}
          <div className="relative">
            <div
              className="flex flex-col items-center justify-center w-40 h-24  bg-[#f9fafc]   rounded-lg cursor-pointer"
              onClick={toggleDropdown}
            >
              <span className="text-3xl  font-bold " style={{ color: '#3488fe' }}>{selectedDays}일!</span>
              <span className="text-md" style={{ color: '#417cc7' }}>시험까지</span>
            </div>

            {/* Dropdown menu */}
            {isDropdownOpen && (
              <div ref={dropdownRef}
                className="absolute bg-[#f9fafc]  rounded-lg mt-2 w-40 max-h-40 overflow-y-scroll">
                {[...Array(31).keys()].map((day) => (
                  <div
                    key={day}
                    className="px-4 py-2 text-center hover:bg-[#f3f4f6] cursor-pointer"
                    onClick={() => selectDays(day)}
                  >
                    {day}일
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Rounded box for completed questions */}
          <div className="flex flex-col items-center justify-center  bg-[#f9fafc]   w-40 h-24 rounded-lg" style={{ borderColor: '#e7f1ff' }}>
            <span className="text-3xl font-bold" style={{ color: '#3488fe' }}>{completedQuestions}</span>
            <span className="text-md" style={{ color: '#417cc7' }}>완료한 문제수</span>
          </div>
        </div>
      )}

      {/* Part Practice Section */}
      <div className="w-full max-w-3xl px-4 mt-8">
      <hr className="my-8 border-t border-gray-300 hidden md:block" />

        <h2 className="text-xl font-semibold text-center">파트별 연습으로 시험 정복하기</h2>
        
        {/* Adjust the margin for part practice buttons */}
        <div className="mt-8 space-y-8">  {/* Increased margin and spacing between parts */}
          {/* Parts 2 to 5 with routing to description pages */}
          {["p1", "p2", "p3", "p4", "p5"].map((partKey, index) => (
            <div key={index} className="flex justify-between items-center">
              <div>
                <p className='text-lg'>파트 {index + 1}: {partTitles[index]}</p> {/* Add titles dynamically */}
                <p className="text-sm text-gray-500">
                  {partProgress[partKey]?.completed || 0}/{partProgress[partKey]?.total || 50} questions
                </p>
              </div>
              <button
                className="px-6 py-2 rounded-lg mx-2"
                style={{ backgroundColor: '#3488fe', color: '#ffffff' }}
                onClick={() => handlePracticeStart(`/part${index + 1}-description`)}
              >
                Start
              </button>
              
            </div>
          ))}
    <hr className="my-8 border-t border-gray-300 hidden md:block" />

          {/* Add Quality AI Mock Tests under Part 5 */}
          <div className="mt-16">
            <div className="flex items-center justify-center mb-8">
              <ChartBarIcon className="w-6 h-6 text-[#3488fe] mr-2" />
              <h3 className="text-xl font-semibold text-gray-800">실전 모의고사</h3>
            </div>
            
            <div className="space-y-4">
              {/* Mock Test 1 */}
              <div className="flex justify-between items-center bg-[#f9fafc] rounded-lg p-4 hover:bg-[#f3f4f6] transition-colors">
                <div>
                  <h3 className="text-lg font-semibold text-gray-800">모의고사 1회</h3>
                  <p className="text-sm text-gray-500">11문항 | 약 30분 소요</p>
                </div>
                <button
                  onClick={() => handleMockTestStart(1)}
                  className="px-6 py-2 rounded-lg text-white"
                  style={{ backgroundColor: '#3488fe' }}
                >
                  Start
                </button>
              </div>

              {/* Mock Test 2 */}
              <div className="flex justify-between items-center bg-[#f9fafc] rounded-lg p-4 hover:bg-[#f3f4f6] transition-colors">
                <div>
                  <h3 className="text-lg font-semibold text-gray-800">모의고사 2회</h3>
                  <p className="text-sm text-gray-500">11문항 | 약 30분 소요</p>
                </div>
                <button
                  onClick={() => handleMockTestStart(2)}
                  className="px-6 py-2 rounded-lg text-white"
                  style={{ backgroundColor: '#3488fe' }}
                >
                  Start
                </button>
              </div>

              {/* Mock Test 3 */}
              <div className="flex justify-between items-center bg-[#f9fafc] rounded-lg p-4 hover:bg-[#f3f4f6] transition-colors">
                <div>
                  <h3 className="text-lg font-semibold text-gray-800">모의고사 3회</h3>
                  <p className="text-sm text-gray-500">11문항 | 약 30분 소요</p>
                </div>
                <button
                  onClick={() => handleMockTestStart(3)}
                  className="px-6 py-2 rounded-lg text-white"
                  style={{ backgroundColor: '#3488fe' }}
                >
                  Start
                </button>
              </div>

              {/* Mock Test 4 */}
              <div className="flex justify-between items-center bg-[#f9fafc] rounded-lg p-4 hover:bg-[#f3f4f6] transition-colors">
                <div>
                  <h3 className="text-lg font-semibold text-gray-800">모의고사 4회</h3>
                  <p className="text-sm text-gray-500">11문항 | 약 30분 소요</p>
                </div>
                <button
                  onClick={() => handleMockTestStart(4)}
                  className="px-6 py-2 rounded-lg text-white"
                  style={{ backgroundColor: '#3488fe' }}
                >
                  Start
                </button>
              </div>

              {/* Mock Test 5 */}
              <div className="flex justify-between items-center bg-[#f9fafc] rounded-lg p-4 hover:bg-[#f3f4f6] transition-colors">
                <div>
                  <h3 className="text-lg font-semibold text-gray-800">모의고사 5회</h3>
                  <p className="text-sm text-gray-500">11문항 | 약 30분 소요</p>
                </div>
                <button
                  onClick={() => handleMockTestStart(5)}
                  className="px-6 py-2 rounded-lg text-white"
                  style={{ backgroundColor: '#3488fe' }}
                >
                  Start
                </button>
              </div>
            </div>
          </div>
          <hr className="my-8 border-t border-gray-300 hidden md:block" />

          {/* New Section: 파트별 템플릿 영작 연습 */}
          <div className="mt-16">  {/* Add some margin-top to separate this section */}
            <h3 className="text-xl font-semibold mb-6 text-center">파트별 템플릿 영작 연습</h3>
            
            {/* Button for 파트2 만능 표현 100가지 연습하기 */}
            <div className="flex justify-between items-center mt-4">
            <div className="flex items-center  px-4 py-2 w-full" style={{ borderColor: '#E8DECF' }}> 
              <p className="text-lg">파트2 만능 표현 정복하기</p>

              </div>
              <button
                className="ml-4 px-6 py-2 rounded-lg text-white"
                style={{ backgroundColor: '#3488fe' }}
                onClick={() => handlePracticeStart('/part2-template-practice')}
              >
                Start
              </button>
            </div>
            {/* Button for 파트2 만능 표현 100가지 연습하기 */}
            <div className="flex justify-between items-center mt-4">
            <div className="flex items-center  px-4 py-2 w-full" style={{ borderColor: '#E8DECF' }}> 
              <p className="text-lg">파트3 만능 표현 정복하기</p>

              </div>
              <button
                className="ml-4 px-6 py-2 rounded-lg text-white"
                style={{ backgroundColor: '#3488fe' }}
                onClick={() => handlePracticeStart('/part3-template-practice')}
              >
                Start
              </button>
            </div>

            <div className="flex justify-between items-center mt-4">
            <div className="flex items-center  px-4 py-2 w-full" style={{ borderColor: '#E8DECF' }}> 
              <p className="text-lg">파트5 만능 표현 정복하기</p>

              </div>
              <button
                className="ml-4 px-6 py-2 rounded-lg text-white"
                style={{ backgroundColor: '#3488fe' }}
                onClick={() => handlePracticeStart('/part5-template-practice')}
              >
                Start
              </button>
            </div>

            
            
          </div>
        </div>
   
    

      </div>
     
      {/* Footer */}
      <footer className="w-full border-t border-gray-200 mt-16 bg-gradient-to-br from-[#f8faff] via-[#f3f8ff] to-[#edf4ff]">
        <div className="max-w-6xl mx-auto px-4 py-12">
          <div className="flex flex-col md:flex-row justify-between">
            {/* Logo and Contact Section - Left Side */}
            <div className="mb-8 md:mb-0">
              <div className="flex items-center mb-4">
                <img 
                  src="/bae-logo.svg" 
                  alt="BaeTos Logo" 
                  className="h-8 w-8 mr-3"
                />
                <p className="text-lg font-semibold text-gray-800">BaeTos</p>
              </div>
              <p className="text-gray-600">
                <a href="mailto:support@baetos.com" className="text-[#3488fe] hover:underline">support@baetos.com</a>
              </p>
            </div>
          </div>

          {/* Business Information - Left Bottom */}
          <div className="text-xs text-gray-500 mt-8 pt-8 border-t border-gray-200">
            <div className="flex flex-wrap gap-x-2">
              <p>상호명: 배랩(Bae Lab)</p>
              <span className="text-gray-300">|</span>
              <p>사업자등록번호: 538-60-00853</p>
              <span className="text-gray-300">|</span>
              <p>대표: 배주영</p>
              <span className="text-gray-300">|</span>
              <p>전화번호: 010-6435-6299</p>
            </div>
            <p className="mt-1">(48060) 부산광역시 해운대구 센텀1로 9 27층</p>
            <div className="mt-2">
              <a href="/terms-of-service" className="text-gray-500 hover:text-[#3488fe] underline">이용약관</a>
            </div>
            <p className="mt-4 text-gray-400">© 2025 BaeTos. All rights reserved.</p>
          </div>
        </div>
      </footer>
      {/* Promotional Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4">
            <div className="text-center">
              <h3 className="text-xl font-bold mb-2">
                🎉 특별 할인 프로모션 진행중!
              </h3>
              <p className="text-gray-600 mb-4">
                {modalMessage === '문제은행' ? (
                  <>
                    지금 구독하시면 <span className="font-bold text-[#3488fe]">40% 할인된 가격</span>으로<br/>
                    무제한 문제은행을 이용하실 수 있습니다.
                  </>
                ) : (
                  <>
                    지금 구매하시면 <span className="font-bold text-[#3488fe]">40% 할인된 가격</span>으로<br/>
                    실전 모의고사를 응시하실 수 있습니다.
                  </>
                )}
              </p>
              <div className="space-y-3">
                <button
                  className="w-full py-3 px-4 bg-[#3488fe] text-white rounded-lg font-bold hover:bg-blue-600 transition-colors"
                  onClick={() => {
                    setIsModalOpen(false);
                    navigate('/pricing');
                  }}
                >
                  할인된 가격으로 시작하기
                </button>
                <button
                  className="w-full py-3 px-4 bg-gray-100 text-gray-600 rounded-lg hover:bg-gray-200 transition-colors"
                  onClick={() => setIsModalOpen(false)}
                >
                  다음에 하기
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;